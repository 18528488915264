import React, {Component, Fragment} from "react";
import { Tabs, Tab, Button, Classes, Dialog, Intent, Tooltip, Callout, Icon } from "@blueprintjs/core";
import {__} from "../../utilities/i18n";
import Loading from "../../utilities/Loading";
import CurrentUser from "../../data/CurrentUser";
import Moment from 'react-moment';
import moment from 'moment';
import CategoryForm from "../../layout/DataTable/single/CategoryForm";

import {compose} from "recompose";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";

const getTeams = loader("../../graphql/getTeams.graphql");
const getCurrentUser = loader("../../graphql/getCurrentUser.graphql");
const addMeToTeam = loader("../../graphql/addMeToTeam.graphql");
const removeMeFromTeam = loader("../../graphql/removeMeFromTeam.graphql");
const deleteTeam = loader("../../graphql/deleteTeam.graphql");
const changeTeam = loader("../../graphql/changeTeam.graphql");

class TeamTabs extends Component
{
	state = {navbarTabId: "EventTab", ...this.props}
	render()
	{
		if(this.props.getCurrentUser.loading )
			return <Loading />;
		//console.log( this.props );
		return <Tabs 
			id="TabsExample" 
			onChange={this.handleTabChange} 
			selectedTabId={this.state.navbarTabId} 
			vertical={true} 
			animated={true}
		>
			<Tab id="EventTab" 		title={__("Team")} 	panel={ this.eventTab() } />
			<Tab id="MembersTab" 	title={__("Members")} panel={ this.membersTab() } />
			{
				CurrentUser._id && CurrentUser.intersectionRole(["admin", "moderator"]) || CurrentUser._id == this.props.owner._id 
					?
					<Tab id="EditTab" title={__("Edit")} panel={ this.editTab() } />
					:
					null
			}
			<Tabs.Expander />
			<Button onClick={this.props.onClose} fill={true} className="mt-4">{__("Close")}</Button>
		</Tabs>
	}
	handleTabChange = navbarTabId =>
	{
		this.setState({navbarTabId})
	}
	eventTab()
	{
		const descr = this.props.description ? <div className='place-descr my-3'>
			<div dangerouslySetInnerHTML={{__html: this.props.description}}/>
		</div> : "";
		const date = this.props.start_date ? moment(this.props.start_date ).format('D MMMM YYYY') : __("Date not defined");
		const _link = this.props.external_url 
			?
				<a href={this.props.external_url} target='_blank'>{__("More information")}</a>  
			: 
				null;
		return <div className="p-tab" >
			<div className="lead ">{this.props.title}</div>
			{descr}
			<div>{_link}</div>
		</div>;
	}
	membersTab()
	{
		const members = this.state.members && this.state.members.length 
			?
			this.state.members.map((e, i) => <li className="list-group-item" key={i}>
				{(e.name ? e.name : "" ) + " " + (e.family_name ? e.family_name : "")}
			</li>)
			:
			<Callout>{__("No members exists")}</Callout>;

		let btn = null;
		if ( this.props.getCurrentUser.getCurrentUser ) {
			btn = this.isMember()
				?
					<Button 
						intent={Intent.DANGER}
						text={__("remove from members of ") + this.state.title}
						onClick={this.onRemoveMember}
					/>
				:
					<Button 
						intent={Intent.SUCCESS}
						text={__("Be member of ") + this.state.title}
						onClick={this.onAddMember}
					/>
		}
		
		return <div className="p-tab">
			<ul className="list-group">
				<div className="">{__("Author")}</div>
				<li className="list-group-item mb-2">
				{
					this.state.owner 
						? 
						(this.state.owner.name ? this.state.owner.name : "" ) 
							+ " " 
								+ (this.state.owner.family_name ? this.state.owner.family_name : "" ) 
						: 
						null
				}
				</li>
				<div className="">{__("Members")}</div>
				{members}
			</ul>
			<div className="pt-3">
				{btn}
			</div>
		</div>;
	}
	editTab = () =>
	{
		console.log( this.props );
		return <Fragment>
			<div className="p-tab" >
				<div>
					<CategoryForm 
						{...this.props}
						ID={this.props._id}							
						data={ this.props }	
						data_type={ "Team" }						
						onChange={this.onChange}
						on={this.onChange}
						vertical={true}
						isHiddenSave={true}
						isHiddenClose={true}
						isHiddenDelete={true}
						isOpen={ true } 
					/>
				</div>				
			</div>
			<div className="p-1">
				<Button text={__("Edit")} onClick={this.onSave} />
					{ (CurrentUser.intersectionRole(['admin']).length > 0 || this.props.owner._id == CurrentUser._id) ? <Button text={__("Delete")} onClick={this.onDelete} intent={Intent.DANGER} /> : null}
			</div>
		</Fragment>
	}	
	onChange=(field, value, id) =>
	{
		console.log(field, value, id);
		let state = {...this.state};
		state[value] = field;
		this.setState( state, console.log( this.state ) );
	}
	onSave = () =>
	{
		let state = 
		{
			id: this.props._id,
			title:this.state.title,
			description:this.state.description,
			owner:this.state._id,
			members: Array.isArray(this.state.members) ? this.state.members.map(e => e._id) : []
		}
		console.log( state, this.props._id );

		this.props.changeTeam({
			variables: state,
			update: (store, update) =>
			{
				console.log(update);
				if(update.data.changeTeam)
				{
					this.props.onClose();
				}
			},
			refetchQueries: [
				{ query: getTeams, variables: {}}
			]
		})

	}
	_______________onChange____ = () =>
	{
		//changeTeam
		return;
		
		const dat = {
			id: this.props._id
		}
		console.log(dat);
		this.props.deleteTeam({
			variables: dat,
			update: (store, update) =>
			{
				console.log(update.data.deleteTeam);
				if(update.data.deleteTeam)
				{
					this.props.onClose();
				}
			},
			refetchQueries: [
				{ query: getTeams, variables: {}}
			]
		})
	}
	onDelete = () =>
	{
		const dat = {
			id: this.props._id
		}
		console.log(dat);
		this.props.deleteTeam({
			variables: dat,
			update: (store, update) =>
			{
				console.log(update.data.deleteTeam);
				if(update.data.deleteTeam)
				{
					this.props.onClose();
				}
			},
			refetchQueries: [
				{ query: getTeams, variables: {}}
			]
		})
	}
	
	isMember()
	{
		return this.props.getCurrentUser.getCurrentUser && Array.isArray(this.state.members) 
			? 
			this.state.members.filter(e => e._id === this.props.getCurrentUser.getCurrentUser._id).length > 0
			:
			false
	}
	//
	onAddMember = () =>
	{
		const dat = {
			team_id: this.state._id
		}
		console.log(dat);
		this.props.addMeToTeam({
			variables: dat,
			update: (store, update) =>
			{
				console.log(update.data);
				this.setState({members : update.data.addMeToTeam.members})
			}
		})
	}
	onRemoveMember = () =>
	{
		const dat = {
			team_id: this.state._id
		}
		console.log(dat);
		this.props.removeMeFromTeam({
			variables: dat,
			update: (store, update) =>
			{
				console.log(update.data.removeMeFromTeam);
				this.setState({members : update.data.removeMeFromTeam.members})
			}
		})
	}
}

export default compose(
    graphql( getCurrentUser, {"name" : "getCurrentUser"} ),
    graphql( addMeToTeam, {"name" : "addMeToTeam"} ),
    graphql( removeMeFromTeam, {"name" : "removeMeFromTeam"} ),
    graphql( deleteTeam, {"name" : "deleteTeam"} ),
    graphql( changeTeam, {"name" : "changeTeam"} ),
)(TeamTabs);