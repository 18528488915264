import React from 'react';
import {AppToaster} from "../../utilities/blueUtils";
import {Intent} from "@blueprintjs/core";
import { compose } from 'react-apollo';
import {withRouter} from "react-router";

const url = process.env.REACT_APP_WPFEST_URI;

class WPUser
{
	static ID;
	static email;
	static display_name;
	static current = "login";
	static clear()
	{
		WPUser.ID = null;
		WPUser.email = null;
	}
	static setStatus(newStatus, data)
	{
		console.log( newStatus );
		console.log( data );
		WPUser.current = newStatus;
		WPUser.doIt(data);
		
	}
	static doIt(data)
	{
		switch(WPUser.current)
		{
			case "login":
				break;
				/*
				const command = "lp_login";
				const body = {email : data ? data.email : ""};
				let trg = url + "wp-json/wpfa/" + command;
				console.log(trg, body);
				fetch( 
					trg, 
					{
						method:"POST",
						//credentials: 'same-origin', 
						headers: {
							'Content-Type': 'application/json'
						},
						body:JSON.stringify({
							code: command,
							args: body
						})
					})
						.then( response => 
						{
							return response.json();							
						})
							.then( r =>
							{
								console.log(r.user);
								WPUser.ID = r.user.id;
								WPUser.email = r.user.email;
								WPUser.display_name = r.user.display_name;
								WPUser.current = "none";
							})
				break;
				*/
			case "none":
				break;
			default:
				//console.log(url + "wp-json/wpfa/" + WPUser.current, data);
				
				var elem = document.createElement('div');
				elem.style.cssText = 'position:absolute;width:100%;height:100%;opacity:0.55;z-index:100000;background:#000;display:flex;justify-content:center;align-items:center; color:#FFFFFF;font-size:40px;';
				elem.id="tokii";
				elem.innerHTML = "<div style='background:#000000;padding:10px;'><i class='fas fa-circle-notch fa-spin'></i></div>";
				document.body.appendChild(elem);
				
				fetch( 
					url + "wp-json/wpfa/" + WPUser.current, 
					{
						method:"POST",
						credentials: 'same-origin', 
						headers: {
							'Content-Type': 'application/json'
						},
						body:JSON.stringify({
							code: WPUser.current,
							args: {...data, user_id:WPUser.ID}
						})
					})
						.then( response => 
						{
							return response.json();							
						})
							.then( r =>
							{
								console.log(r);
								console.log(r);
								let tokii = document.getElementById("tokii");
								tokii.parentNode.removeChild(tokii);
								if( r.msg  )
								{
									const actions = r.msg_action ? {
											onClick: () => WPUser.opener(r.msg_action[0]),
											text: r.msg_action[1],
										} : {};
									{
										
									}
									AppToaster.show({
										intent: r.msg_type=="error" ? Intent.DANGER : Intent.SUCCESS,
										action: actions,
										icon: "error",
										duration: 5000,
										className: "p-5",
										message: r.msg
									});
								}
								if( r.error )
								{
									AppToaster.show({
										intent: Intent.DANGER,
										icon: "error",
										duration: 5000,
										className: "p-5",
										message: r.error
									});
								}
								WPUser.current = "none";
							})
		}
		
	}
	static opener(url)
	{
		//window.opener.location(url);
		var otherWindow = window.open(url);
		//otherWindow.opener.location(url);
	}
}

export default compose (
	withRouter
)(WPUser)