import React, {Component} from 'react';
import Layout from "./layout";
import layouts from "./layouts";
import CurrentUser from "./data/CurrentUser";
import {compose} from "recompose";
import { Query, graphql, withApollo} from "react-apollo";
import {withRouter} from "react-router";
import {loader} from "graphql.macro";
import {AppToaster} from "./utilities/blueUtils";
import {Intent} from "@blueprintjs/core";
import {__} from "./utilities/i18n";
import Loading from "./utilities/Loading";
import gql from "graphql-tag";


const l_token = loader("./graphql/token.graphql");

class Body extends Component
{
	constructor(props)
	{
		super(props);
		const css = localStorage.getItem("css");
		const style = css ? css : layouts.style;
		const fl = localStorage.getItem("fluid");
		//console.log(typeof fl, fl);
		const fluid = typeof fl !== "undefined" ? fl == "1" : 1;
		this.state={style, fluid }

	}

	componentWillMount() {
		if(localStorage.getItem('client_token') && this.state.is_client_token ){

		}else{
			this.props.token({
				variables:
					{
						input: {
							grant_type: "jwt-bearer",
							assertion: process.env.REACT_APP_ASSERTION
						}
					},
				update: (store, { data: { token } }) =>
				{
					localStorage.setItem(
						'client_token',
						token.access_token
					);
					this.setState({"is_client_token": 1 });


				}
			})
		}

	}

	render()
	{
		let content;
		if(this.state.is_client_token)
		{
			const query1 = "query userInfo {userInfo {_id name family_name roles gender email}}";
			const query_gql1 = gql`${query1}`;
			content = <Query query={query_gql1}>
			{
				({ loading, error, data, client}) => 
				{
					if( loading) 	return <Loading/>;
					if( data ) 		
					{						
						//console.log(data.userInfo);
						CurrentUser.getData( data.userInfo );
					}
					return <div className={"container" + (this.state.fluid ? "-fluid ": "") +" cont"}>
						<Layout
							name="FlowMaster"
							onChangeStyle = { this.onChangeStyle }
						/>
					</div>
				}
			} 
			</Query>
		}
		else
		{
			content = <Loading />
		}
		
		return (
			<div className="full">
				<header>
				</header>
				<main>
					{content}

				</main>
				<footer>
				</footer>
				 <div>
					<link rel="stylesheet" type="text/css" href={ this.state.style + "?rand=1234" } />
				</div>
			</div>
		);
	}	
	onChangeStyle = style => 
	{
		localStorage.setItem("css", style.style);
		localStorage.setItem("fluid", style.fluid? 1 : 0);
		this.setState( {...style} );
	}
}

export default compose(

	graphql(l_token, {"name": "token"}),
	withApollo,
	withRouter
)(Body);
