import React, {Component, Fragment} from "react";
import layouts from "../layouts";
import LayoutIcon from "./LayoutIcon";
import { NavLink } from 'react-router-dom'


class LayoutComments extends Component
{
	state = { }
	render()
	{
		return <div className=" ">
			<div className=" " >
				<NavLink 
					to={layouts.comments[0].route}
				>					
					<LayoutIcon
						src={ layouts.comments[0].icon }
						className="layout-header-icon"
					/>
				</NavLink>
			</div>
		</div>
	}
}

export default LayoutComments;