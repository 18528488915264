import React, {Component, Fragment} from "react";
import layouts from "../layouts";
import LayoutIcon from "./LayoutIcon";
import { NavLink } from 'react-router-dom';
import {__} from "../utilities/i18n";


class LayoutHelp extends Component
{
	state = { }
	render()
	{
		const children = layouts.help[0].children && layouts.help[0].children.length > 0
			?
				layouts.help[0].children.map((ee, i) =>
				{
					const rt = "/" + layouts.help[0].route + "/" + ee.route;
					return <NavLink 
						to={ rt }
						className={ "podmenu" } 
						activeClassName={ "active" }
						key={i}
						route={ rt }
					>
						{ __(ee.title) }
					</NavLink>
				})
			:
			null;
		return <Fragment>
			<NavLink 
				to={"/" + layouts.help[0].route}
				className="layout-header-help"
			>					
				<LayoutIcon
					src={ layouts.help[0].icon }
					className="layout-header-icon"
				/>
				<span>{ layouts.help[0].title }</span>
			</NavLink>
			{children}
		</Fragment>
	}
}

export default LayoutHelp;