import React, {Component, Fragment} from "react";
import layouts from "../layouts";
import LayoutIcon from "./LayoutIcon";
import { NavLink } from 'react-router-dom'


class LayoutBells extends Component
{
	state = { }
	render()
	{
		return <div className="layout-header-bell">
			<div className=" " >
				<NavLink 
					to={ layouts.comments[0].route }
				>					
					<LayoutIcon
						src={ layouts.bells[0].icon }
						className="layout-header-icon"
					/>
					<span>
						
					</span>
				</NavLink>
			</div>
		</div>
	}
}

export default LayoutBells;