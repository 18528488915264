import React from "react";
import BasicState from "./BasicState";
import layouts from "../layouts";
//import BasicTable from "./data";
import DataTable from "../layout/DataTable";
import {getQuery, getMutation} from "../layout/DataTable/form";
import {compose} from "recompose";
import { Query, withApollo } from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";
import Loading from "../utilities/Loading";

class DataTableState extends BasicState
{
	basic_state_data() 
	{
		return { }
	}
	myState = route =>
	{
		//console.log(this.props);
		return this.get_data( route.data_type);
	}
	getRoute = () =>
	{
		return "data-table";
	}
	
	get_data(data_type) 
	{
		this.query_gql = getQuery( data_type );
		//
		const f = <Query query={this.query_gql}>
		{
			({ loading, error, data, client}) => 
			{
				if( loading)
				{
					return <Loading/>;
				}
				//console.log(data);
				//console.log(error);
				if(data)
				{
					const dt = layouts.schema[ data_type];
					//const fills = dt ? dt.admin_data.fill : ["#4580E6","#1F4B99"];
					//const fills = ["#3f586b", "#293742"];
					const fills = ["transparent", "transparent"];
					//объявление запроса Apollo-мутации 
					const mutation = getMutation( data_type );
					
					return <DataTable
						theadColor = {fills[0]}
						trColor = {fills[1]}
						data_type = { data_type }
						data={data[layouts.schema[ data_type ].apollo_query]}
						mutation={mutation}
						route={this.props.route}
						isList={this.props.is_list}
					/>;
				}
				if(error)
				{
					return error.toString();
				}
			}
		}
		</Query>;
		return f;
	}
}
export default compose(
	withApollo,
	withRouter
)(DataTableState);