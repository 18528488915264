import React, {Component} from "react";
import { AnchorButton, Button, ButtonGroup, Classes, Dialog, Intent, Tooltip, Card } from "@blueprintjs/core";
import {String, URL, DateTime, Radio, Geo, TextField} from "../../layout/DataTable/form";
import {__} from "../../utilities/i18n";
import Loading from "../../utilities/Loading";

import WPUser from "./WPUser";

import { compose } from 'react-apollo';
import {withRouter} from "react-router";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";


const getCurrentUser = loader("../../graphql/getCurrentUser.graphql");

class WPFest extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			state:this.props.isOpen ? "enter" : "none",
			status : "none",
			command : "",
			agruments : {}
		}
	}
	
	componentWillReceiveProps ( nextProps )
	{
		//console.log(nextProps.status, this.state.status);
		if( typeof nextProps.isOpen !== "undefined" )
		{
			//
			this.setState({
				state: nextProps.isOpen 
					? 
						(this.state.state == "none" ? "enter" : this.state.state)
					: 
						"none"
			});
		}
		if(nextProps.status != this.state.status)
		{
			console.log( nextProps.status, nextProps.arguments );
			WPUser.setStatus(nextProps.status, nextProps.arguments);
			this.setState({ status: nextProps.status, arguments: nextProps.arguments });
		}
	}
	componentDidMount()
	{
		WPUser.clear();
	}
	render()
	{
		return null;
		//WPUser.doIt( this.props.getCurrentUser.getCurrentUser );
		//console.log(this.state.state);
		let content;
		switch(this.state.state)
		{
			case "enter":
				content = this.getEnterForm();
				break;
			case "login":
				content = this.getLoginForm();
				break;
			case "none":
			default:
				content = "";
				break;
		}
		return <Dialog
			className="bp3-dark"
			isOpen={ this.state.state !== "none" }
			onClose={ this.onToggle }
			title=""
		>
			<div className="p-4">
				{content}
			</div>
		</Dialog>
		
		
	}
	getEnterForm()
	{
		return <div style={{ maxWidth: 420 }} className="text-center">
			<div className="lead mb-3">
				{__("Order of actions")}
			</div>
			<Button
				fill={true}
				state="login"
				text={__("Login in WP-Fest")}
				onClick={this.onState}
			/>
			<div className="small text-secondary mb-3">
				{__("Login, if you have account in WP-Fest.")}
			</div>
			<Button
				fill={true}
				state="login"
				text={__("Continue create WPFest under Administrator's account")}
				onClick={this.onState}
			/>
			<div className="small text-secondary mb-3">
				{__("You may create WPFest for administration's account and call WPFest-administrator to have the power to this.")}
			</div>
		</div>
	}
	getLoginForm()
	{
		return <div>
			<div className="description">
				{__("Login, if you have account in WP-Fest.")}
			</div>
			<String
				field={ "login" } 
				title={ __("Login") } 
				editable={ true } 
				value={ this.state.login }
				vertical={ false }		
				on={this.onTitle}
			/>
		</div>
	}
	onToggle = () =>
	{
		const state = this.state.state == "none" ? "enter" : "none";		
		this.setState({ state });
	}
	onState = evt =>
	{
		const state = evt.currentTarget.getAttribute("state");
		this.setState({ state: state });
	}
}


export default compose(
	withApollo,
	withRouter
)(WPFest);