import React, {Component, Fragment} from "react";
import BasicState from "./BasicState";
import { NavLink, Link } from 'react-router-dom';
import {__} from "../utilities/i18n";
import layouts from "../layouts";

class HTMLState extends BasicState
{
	
	getRoute = route =>
	{
		return this.props.route;
	}
}
export default HTMLState;