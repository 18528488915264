import React, {Component, Fragment} from "react";
import LayoutMenuLeft from "./LayoutMenuLeft";
import layouts from "../layouts";
import LayoutMain from "./LayoutMain";
import LayoutMenuMain from "./LayoutMenuMain";

class LayoutContent extends Component
{
	state = {
		current:this.props.current
	}
	componentWillReceiveProps (nextProps )
	{
		this.setState({
			current: nextProps.current
		});
	}
	render()
	{
		const menu_left = !layouts.config || layouts.config.menu_left
			?
			<LayoutMenuLeft current={this.state.current} onCurrent={this.props.onCurrent} />
			:
			null;
		return <div className="layout-content">
			{menu_left}
			<LayoutMenuMain current={this.state.current} onCurrent={this.props.onCurrent} />
			<LayoutMain onChangeStyle={ style => this.props.onChangeStyle( style ) } />
		</div>
	}
}
export default LayoutContent;