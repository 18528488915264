import React, {Component} from "react";
import addEmpty from "./addEmpty";
import String from "./String";
import Address from "./Address";
import TextField from "./TextField";
import ID from "./ID";
import URL from "./URL";
import Email from "./Email";
import Link from "./Link";
import ExtendedLink from "./ExtendedLink";
import DateTime from "./DateTime";
import RGB from "./RGB";
import Color from "./Color";
import Geo from "./Geo";
import MultiSelect from "./MultiSelect";
import Selector from "./Selector";
import Radio from "./Radio";
import Checkbox from "./Checkbox";
import Array from "./Array";
import Phone from "./Phone";
import Boolean from "./Boolean";
import Reloadbled from "./Reloadbled";
import Loading from "../../../utilities/Loading";
import layouts from "../../../layouts";

import {compose} from "recompose";
import { Query, withApollo } from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";


export {addEmpty};
export {Color};
export {String};
export {Address};
export {TextField};
export {Email};
export {URL};
export {ID};
export {Link};
export {ExtendedLink};
export {DateTime};
export {RGB};
export {Geo};
export {MultiSelect};
export {Radio};
export {Checkbox};
export {Phone};
export {Array};
export {Boolean};
export {Reloadbled};

export function getQueryArgs(data_type, is_mutation=false)
{	
	const aq = layouts.schema[ data_type ].apollo_query;
	const apollo_fields = layouts.schema[ data_type ].apollo_fields;
	if(!apollo_fields)
	{
		return "error layouts.json";
	}
	//const fl = (Object.keys( apollo_fields )).join(" ");
	let fl = [];
	for( let fld in apollo_fields)
	{
		if(apollo_fields[fld].type == "array")
		{
			switch(apollo_fields[fld].component)
			{
				case "string":
				case "integer":
				case "float":
				case "email":
				case "phone":
					fl.push( fld + "");
					break;
				default:
					if(!is_mutation)
					{
						let fll = [];
						for( let nnn in apollo_fields[fld].external_fields)
						{
							fll.push( apollo_fields[fld].external_fields[nnn] );
						}
						fl.push( fld + " { " + fll.join(" ") + " } " );
					}
					else
					{
						fl.push( fld + "_id " );
					}
					break;
			}
			fl.push(  );
		}
		else if(apollo_fields[fld].type == "external")
		{
			if(!is_mutation)
			{
				const fll = apollo_fields[fld].external_fields;
				fl.push( fld + " { " + fll.join(" ") + " } " );
			}
			else
			{
				fl.push( fld + "_id " );
			}
		}
		else if(apollo_fields[fld].no_load)
		{
			
		}
		else
		{
			fl.push( fld );
		}
	}
		return fl;
}

export function getQuery( data_type )
{
	const aq = layouts.schema[ data_type ].apollo_query;
	const query = "query " + aq + " { " + aq + " { " + getQueryArgs(data_type).join(" ") + " } }";
	return gql`${query}`;
}
export function getMutation( data_type )
{
	const aq = layouts.schema[ data_type ].apollo_query;
	const query = "mutation change" + data_type +"($id:ID $input:" + data_type +"Input){ change" + data_type +"(id: $id, input: $input){ " + getQueryArgs( data_type ) + " }}";
	return gql`${query}`;
}

export function getDelete( data_type )
{
	const aq = layouts.schema[ data_type ].apollo_query;
	const query = "mutation delete" + data_type +"($id:ID!){ delete" + data_type +"(id: $id)}";
	return gql`${query}`;
}

export function form(title, value, onChange, type, params, apollo_field )
{	
	const editable = typeof params.editable !== "undefined" ? params.editable : true;	
	const field = params.field;		
	const className = params.className;		
	const visibled_value = 
		params.visibled_value 
			? 
			params.visibled_value
			: 
			'title';
	const external_fields = params.external_fields;
	const extended_link = params.extended_link;
	const external_state = params.external_state;
	const external_link_data = params.external_link_data;
	const inner_link = params.inner_link;
	const vertical = params.vertical;
	const list = params.list;
	const addList = params.addList;
	const isOpen = params.isOpen;
	
	switch(type)
	{
		case "array":			
			switch( params.component )
			{
				case "string":
					return <Array
						field={ field } 
						editable={ editable } 
						title={ title } 
						value={ value }
						vertical={ vertical }
						on={onChange}
					/>;
					break;
				case "array":
					break;
				default:	
					//console.log( params.component );
					//console.log( layouts.schema[ params.component ] );
					const aq = layouts.schema[ params.component ].apollo_query;
					const fl = external_fields.join(" ")
					const query = "query " + aq + " { " + aq + " { " + fl + " } }";
					//console.log("query");
					//console.log(query);addList
					const query_gql = gql`${query}`;
					//
					const f = <Query query={ query_gql }  >
					{
						({ loading, error, data, client}) => 
						{
							if( loading)
								return <Loading/>;
							//console.log(data[aq]);
							let listData = [];
							if(addList)
							{
								listData = data[aq].concat( addList );
							}
							else if(list)
							{
								listData = list;
							}
							else
							{
								listData = data[aq];
							}
							//console.log( "array.MultiSelect", value, field, field );
							if(data || list || value)					
								return <MultiSelect
									multiple={true}
									field={ field } 
									editable={ editable } 
									title={ title } 
									value={ value }
									data ={ listData }
									visibled_value={ visibled_value }
									vertical={ vertical }
									on={onChange}
								/>
						}
					}
					</Query>;
					return f;
			}
			break;
		case "external":
			if( extended_link )
			{
				//добавляем дополнительные поля в роутинг (сейчас только :id)
				let route = extended_link.route;
				if( extended_link.add)
				{
					switch( extended_link.add)
					{
						case ":id":
							 route += value ? value._id : " ";
							break;
						default:
							route = route;
					}
				}
				return <ExtendedLink 
					field={ field } 
					extended_link={ route }
					title={ title } 
					_id={ 1 } 
					value={ value ? value[ layouts.schema[ params.component ].visibled_value ] :  " -- " }
					vertical={ vertical }
					external_state={external_state}
					external_link_data={external_link_data}
				/>
			}
			else if( inner_link == 1 )
			{						
				return <Link 
						field={ field } 
						title={ title } 
						_id={ 1 } 
						value={ value ? value[ visibled_value ] :  " -- " }
						external_state={external_state}
						vertical={ vertical }
						external_link_data={external_link_data}
					/>
			}
			else if( params.component )
			{
				const aq = layouts.schema[ params.component ].apollo_query;
				const fl = external_fields.join(" ")
				const query = "query " + aq + " { " + aq + " { " + fl + " } }";
				//console.log("query");
				//console.log(query);
				const query_gql = gql`${query}`;
				//
				const f = <Query query={ query_gql } >
				{
					({ loading, error, data, client}) => 
					{
						if( loading)
							return <Loading/>;
						
						let listData = [];
						if(addList)
						{
							listData = data[aq].concat( addList );
						}
						else if(list)
						{
							listData = list;
						}
						else
						{
							listData = data[aq];
						}
						console.log(params);
						if (!value && listData && params.show_first) {
							value = listData[0];
							onChange(value, field);
						}
						//console.log( value );
						if(data)					
							return <Selector
								field={ field } 
								editable={ editable } 
								title={ title } 
								value={ value }
								data ={ listData }
								visibled_value={ visibled_value }
								vertical={ vertical }
								on={onChange}
							/>
					}
				}
				</Query>;
				return f;
			}
			else
			{
				return <String 
					field={ field } 
					title={ title } 
					editable={ false } 
					visibled_value={visibled_value}
					value={ value ? value[visibled_value] : "" }
					vertical={ vertical }		
					on={onChange}
				/>
			}
			break;
		case "component":
			const aq = layouts.schema[ params.component ].apollo_query;
			const fl = external_fields.join(" ")
			const query = "query " + aq + " { " + aq + " { " + fl + " } }";
			//console.log("query");
			//console.log(query);
			const query_gql = gql`${query}`;
			//
			const f = <Query query={ query_gql } >
			{
				({ loading, error, data, client}) => 
				{
					if( loading)
						return <Loading/>;
					
					let listData = [];
					if(addList)
					{
						listData = data[aq].concat( addList );
					}
					else if(list)
					{
						listData = list;
					}
					else
					{
						listData = data[aq];
					}
					//console.log( value );
					if(data)					
						return <String 
							field={ field } 
							title={ title } 
							editable={ false } 
							visibled_value={visibled_value}
							value={ value ? value[visibled_value] : "" }
							vertical={ vertical }		
							on={onChange}
						/>
				}
			}
			</Query>;
			return f;
			break;
		case "date":
			return (
				<DateTime
					component={ params.component }  
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					value={ value }
					values={params.values}
					visibled_value={visibled_value}
					vertical={ vertical }
					on={onChange}
				/>
			);
			break;
		case "color":
			return (
				<Color 
					component={ params.component } 
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					value={ value } 
					visibled_value={visibled_value}
					values={params.values}
					vertical={ vertical }
					on={onChange}
				/>
			);
			break;
		case "rgb":
			return (
				<RGB 
					component={ params.component } 
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					value={ value } 
					visibled_value={visibled_value}
					values={params.values}
					vertical={ vertical }
					on={onChange}
				/>
			);
			break;
		case "boolean":
			return (
				<Boolean 
					component={ params.component }
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					value={ value }
					values={params.values}
					vertical={ vertical }
					_id={params._id}
					on={onChange}
				/>
			);
			break;
		case "phone":
			return (
				<Phone 					
					component={ params.component } 
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={onChange}
				/>
			);
			break;
		case "geo":
			//console.log(params._id);
			return (
				<Geo					
					component={ params.component } 
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					value={ value }
					values={params.values}
					vertical={ vertical }
					_id={params._id}
					on={onChange}
					isOpen={isOpen}
				/>
			);
			break;
		case "address":
			//console.log(params._id);
			return (
				<Address
					component={ params.component }
					title={ title }
					field={ field }
					editable={ editable }
					className={ params.className }
					visibled_value={visibled_value}
					style={ params.style }
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={ onChange }
				/>
			);
			break;
		case "select":
			let listData = [];
			if(addList)
			{
				//listData = data[aq].concat( addList );
			}
			else if(list)
			{
				//listData = list;
			}
			else
			{
				//listData = data[aq];
			}
			return (
				<MultiSelect 
					component={ params.component } 
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={onChange}
					data={listData}
				/>
			);
			break;
		case "checkbox":
			return (
				<Checkbox 
					component={ params.component } 
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={onChange}
				/>
			);
			break;
		case "radio":
			return (
				<Radio 
					component={ params.component } 
					field={ field }
					title={ title } 
					editable={ editable }
					className={ params.className } 
					visibled_value={visibled_value}
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={onChange}
				/>
			);
			break;
		case "link":
			//console.log(value);
			return (
				<Link 
					field={ field }
					title={ title } 
					className={ params.className } 
					value={ value }
					_id={params._id}
					external_state={external_state}
					external_link_data={external_link_data}
					vertical={ vertical }
				/>
			);
			break;
		case "email":
			return (
				<Email
					title={ title }
					field={ field }
					editable={ editable }
					className={ params.className }
					style={ params.style }
					value={ value }
					visibled_value={visibled_value}
					values={params.values}
					vertical={ vertical }
					on={ onChange }
				/>
			);
			break;
		case "id":
			return (
				<ID
					title={ title }
					field={ field }
					editable={ editable }
					className={ params.className }
					style={ params.style }
					visibled_value={visibled_value}
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={ onChange }
				/>
			);
			break;
		case "url":
			return (
				<URL
					title={ title }
					field={ field }
					editable={ editable }
					className={ params.className }
					visibled_value={visibled_value}
					style={ params.style }
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={ onChange }
				/>
			);
			break;
		case "reloaditabled":
			return (
				<Reloadbled
					title={ title }
					field={ field }
					editable={ editable }
					className={ params.className }
					visibled_value={visibled_value}
					style={ params.style }
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={ onChange }
				/>
			);
			break;
		case "string":
		default:
			return (
				<String
					title={ title }
					field={ field }
					editable={ editable }
					className={ params.className }
					visibled_value={visibled_value}
					style={ params.style }
					value={ value }
					values={params.values}
					vertical={ vertical }
					on={ onChange }
					apollo_field={ apollo_field }
				/>
			);
	}
}
class InputForm extends Component
{
	render()
	{
		const { title, value, type, params, onChange, field, vertical } = this.props;
		const { _id, editable, style, values, className, component, visibled_value, external_fields, extended_link, inner_link, external_state,external_link_data, list, addList, isOpen, show_first } = this.props;
		//console.log(_id);
		return form( 
			title, 
			value, 
			onChange, 
			type, 
			{ _id, editable, style, values, className, component, visibled_value, external_fields, extended_link, inner_link, external_state, external_link_data, field, vertical, list, addList, isOpen, show_first },
			this.props
		);
	}
}
export default compose(
	withApollo,
	withRouter
)(InputForm);