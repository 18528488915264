import React, {Component, Fragment} from "react";
import {__} from "../utilities/i18n";
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup 
 } from "@blueprintjs/core";

import {withRouter} from "react-router";


class LayoutScope extends Component
{
	state={login: "", password: ""}

	render()
	{


		return <div className="layout-center">
			<Card 
				interactive={true}
				className="layout-message p-4 layout-center"
			>
				<div className="title mb-auto">{__("Scopes")}</div>
				<div className="my-3 text-light text-center">
					{this.props.text}
				</div>
				<div className="btn-group">
					<div className="btn btn-primary btn-fill" onClick={this.onAccept} >
						{__("Accept")}
					</div><div className="btn btn-danger btn-fill" onClick={this.onDecline} >
						{__("Decline")}
					</div>
				</div>
			</Card>
		</div>
	}
	onAccept = evt =>
	{
		this.props.onAccept(true);
	}
	onDecline = evt =>
	{
		this.props.onDecline(true);
	}
}

export default withRouter(LayoutScope);

