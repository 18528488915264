import React, {Component} from "react";
import {__} from "../../../utilities/i18n";
import Loading from "../../../utilities/Loading";
import layouts from "../../../layouts";
import InputForm, {String, Link, ExtendedLink, DateTime, RGB, Geo, MultiSelect, Checkbox, Phone, Array, Boolean} from "../form";
import {  
	Intent, Tag,
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup 
} from "@blueprintjs/core";
import CurrentUser from "../../../data/CurrentUser";

import {compose} from "recompose";
import { Query, withApollo } from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";
 
class CategoryForm extends Component
{
	constructor(props)
	{
		super(props);
		//console.log( this.props.data )
		this.state = this.props.data;
	}
	componentWillReceiveProps ( nextProps )
	{
		if ( nextProps.isOpen != this.props.isOpen )
			this.setState({...this.props.data});
		if( nextProps.data != this.state ||  nextProps.isOpen != this.props.isOpen )
		{
			//if(this.props.isMain)
			//	console.log( nextProps.data )
			//this.setState({...this.props.data});
			this.setState({...nextProps.data });
		}
	}
	getDataType = () =>
	{
		let dataType = false;
		for(var i in layouts.schema)
		{
			if(i == this.props.data_type)
			{
				dataType = i;
				break;	
			}
		}
		return dataType;
	}
	getFields ( ) 
	{
		const dataType = this.getDataType();
		if(dataType)
		{
			return layouts.schema[dataType].apollo_fields;
		}
		else
		{
			return {_id	: "string", title : {"type":"string"}};
		}
	}
	drawForms ( ) 
	{	
		const dataType = this.getDataType();
	
		if(this.props.isMain)
		{
			//console.log( this.props.addList )
			//console.log( this.state.activate_sources )
			//console.log( this.props.data.themes )
		}
		let fields = [];
		const _fields = this.getFields( );
		for( let field in _fields )
		{
			//if(this.props.isMain) 
			//	console.log( this.state[field] );
			
			if( 
				field == "_id" 
				|| field == "admin_data"
				|| (
					_fields[field].caps 
					//&& Array.isArray( _fields[field].caps ) 
					&& CurrentUser.intersectionRole( _fields[field].caps).length == 0
				)
			) continue;
			const editable = typeof this.props.editable != "undefined" ? this.props.editable : _fields[field].editable;
			// добавляем данные для формирования "умной" ссылки, если в схеме указано, что она есть
			let external_link_data = { orig: {_id:this.props._id}};
			if(_fields[field].external_state)
			{
				//добавляем в ссылку данные дочерних элементов объекта, указанные в схеме
				for(let es in  _fields[field].external_state)
				{
					external_link_data[es] = {
						component: _fields[es].component, 
						...this.props[es]
					};
				}
			}	
			const compp = _fields[field].component;
			//console.log(field, compp, _fields);
			//console.log(compp, field, this.state[field], _fields);
			const visibled_value = compp
				?
				layouts.schema[compp] && layouts.schema[compp].visibled_value 
					? 
					layouts.schema[compp].visibled_value
					: 
					'title'
				:
				"title";
			fields.push(
				<InputForm 
					field={ field } 
					key={ field }
					_id={this.props.ID}
					list={this.props.list}
					addList={ this.props.addList ? this.props.addList[field]: null }
					on={this.on}
					onChange={this.on}
					{..._fields[field]}
					editable = {editable}
					value={ this.state[field] }
					vertical={ this.props.vertical }
					visibled_value={ visibled_value }
					external_link_data={external_link_data}
				/>
			);
		};
		return fields;
	}
	getDelBtn () 
	{
		return this.props.isNew || this.props.isHiddenDelete 
			? 
			null 
			: 
			<Popover
				position={Position.TOP_LEFT}
				content={
					<div className="square p-3">
						<div>
							<p>
								{__("Are you realy want delete?")}
							</p>
							<ButtonGroup className="p-2 tex-right">
								<Button
									intent={Intent.DANGER}
									text={__("Yes")}
									onClick={this.onDelete}
								/>
							</ButtonGroup>
						</div>						
					</div>						
				}
			>
				<Button
					intent={Intent.DANGER}
					text={__("Delete")}
				/>
			</Popover>
	}
	render()
	{		
		//if(this.props.data.isMain)	
		//	console.log(this.props.data.themes);
		return <div id={this.props.htmlID} className="category_card" style={{"max-width": "100vw"}}>
			{this.drawForms()}
			<div className="row">
				<div className="col-md-3">
				
				</div>
				<div className="col-md-7 bp3-dark">
					<ButtonGroup className="p-2 tex-right">
					{
						this.props.isHiddenSave ? null :
						<Button
							text={this.props.saveLabel ? this.props.saveLabel : __("Update")}
							intent={this.props.intent}
							onClick={this.onSave}
						/>
					}
					{this.getDelBtn()}
					{
						this.props.isHiddenClose ? null :
						<Button
							text={__("Close")}
							onClick={this.props.onClose}
						/>
					}
					</ButtonGroup>
				</div>
			</div>			
		</div>
	}
	title = evt =>
	{
		const txt = evt.currentTarget.value;
		this.props.onChange("title", txt, this.props.ID);
		this.setState({title : txt});
	}
	onSave = () =>
	{		
		//let state = {...this.state};
		//delete state._id;
		//delete state.__typename;
		console.group('CategoryForm.onSave');
		let state = {};
		const _fields = this.getFields( );
		for( let field in _fields )
		{
			if(_fields[field].type)
			{
				if(_fields[field].type == "external")
				{
					console.log(field, this.state[ field ]);
					state[ field + "_id" ] = this.state[ field ] ? this.state[ field ]._id : null;
				}
				else if(_fields[field].type == "array" && _fields[field].component)
				{
					console.log(field, this.state[ field + "_ids"  ], this.state[ field ]);
					state[ field + "_ids" ] = this.state[ field ] ? this.state[ field ].map(e => e._id) : [];
				}
				else
				{
					console.log(field, this.state[ field ]);
					state[ field ] = this.state[ field ];
				}
			}
			else
			{
				console.log(field, this.state[ field ]);
				state[ field ] = this.state[ field ];
			}
		}
		
		//console.log(_fields);
		console.log(state);
		//console.log(this.props.ID);
		console.groupEnd();
		this.props.onSave( state, this.props.ID );
	}
	onDelete =() =>
	{
		this.props.onDelete( this.props.ID );
	}
	on = (value, field) =>
	{
		console.group('CategoryForm.on');
		console.log(value);
		console.log(field);
		console.groupEnd();
		var obj = {}
		obj[field] = value;
		this.setState(obj, function()
		{

			if(this.props.on){
				this.props.on(value, field, this.props.ID);
			}

			console.log(this.state, obj)
		});
		
	}
	
	getElementScheme(field)
	{
		const my_apollo_fields 	= layouts.schema[this.props.data_type].apollo_fields;
		const my_field 			= my_apollo_fields[field];
		return my_field.component;
	}
}
export default compose(
	withApollo,
	withRouter
)(CategoryForm);