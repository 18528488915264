import React, {Fragment} from "react";
import BasicState from "./BasicState";
import Propfile from "./ProfileState/Profile";
import {__} from "../utilities/i18n";
import {AppToaster} from "../utilities/blueUtils";
import { Intent } from "@blueprintjs/core";
import layouts from "../layouts";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {compose} from "recompose";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";
import Loading from "../utilities/Loading";

const l_registerUser = loader("../graphql/registerUser.graphql");

class RegisterState extends BasicState
{
	render()
	{
		const user = { avatar:"../img/user-logo.svg", roles: ["User"] };
		return  <div className="layout-state">
			<div className="layout-state-head">
				<span className={ "layout-state-logo " + this.state.route.icon } />
				<div className="layout-state-title">
					{ __( this.state.route.title ) }
				</div>
			</div>
				<Propfile 
					user={user}	
					onEditField={this.onEditField}
					onChange={this.onChange}
				/>
				
			</div>
	}
	onEditField = state =>
	{		
		console.log(state);
		//this.setState({})
	}
	getRoute = () =>
	{
		return "register";
	}
	getExtends = () =>
	{
		return null;
	}
	onChange = state =>
	{
		console.log( "state" );
		console.log( state );
		delete state.avatar;
		delete state.roles;
		if(this.state.email === "")
		{
			AppToaster.show({  
				intent: Intent.DANGER,
				icon: "error", 
				message: __("email not be empty")		
			});
			return;
		}
		if(this.state.name === "")
		{
			AppToaster.show({  
				intent: Intent.DANGER,
				icon: "error", 
				message: __("name not be empty")		
			});
			return;
		}
		if(this.state.password === "")
		{
			AppToaster.show({  
				intent: Intent.DANGER,
				icon: "error", 
				message: __("password not be empty")		
			});
			return;
		}
		this.props.registerUser({
			variables:
				{
					input: state
				},
			update: (store, { data: { registerUser } }) =>
			{
				AppToaster.show({
					intent: Intent.SUCCESS,
					icon: "tick",
					message: __("User registered")
				});
				this.props.history.push( "/" );
			}
		})
		return;
	}

}
export default compose(
	graphql(l_registerUser, {"name": "registerUser"}),
	withApollo,
	withRouter
)(RegisterState);