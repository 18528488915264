import React, {Component, Fragment} from "react";
import LayoutIcon from "../../layout/LayoutIcon";
import CategoryForm from "../../layout/DataTable/single/CategoryForm";
import {__} from "../../utilities/i18n";
import Loading from "../../utilities/Loading";
import Post from "./Post";
import ChatHead from "./ChatHead";

import { compose, mapProps } from "recompose";
import { loader } from 'graphql.macro';
import {graphql, Query, withApollo, Mutation} from 'react-apollo';
import gql from "graphql-tag";
import {withRouter} from "react-router";
const getSourcePostLog = loader("../../graphql/getSourcePostLog.graphql");
const getSource = loader("../../graphql/getSource.graphql");

class ChatListState extends Component
{
	componentDidMount()
	{
		//console.log(this.props )
		//this.props.history.push( this.props.location.pathname + "#" + this.props.match.params.id );
		//console.log("componentDidMount");
	}
	render()
	{
		const { loadingSRC, getSourcePostLog } = this.props;
		if(loadingSRC )
			return <Loading />;
		console.log(this.props )
		const posts 	= getSourcePostLog.posts || [];
		const source	= getSourcePostLog.source || {};
		/*
		const lent 		= posts.map((e,i) =>
		{
			return <div className="row data-list input " key={i}>
				<div className="col col-md-12" id={e._id}>
					<CategoryForm 
						{...e}
						editable={false}
						ID={e._id }							
						data={e}	
						data_type={"Post"}
						isOpen={ true } // for Yandex Maps
						isHiddenSave={true}
						isHiddenClose={true}
					/>
				</div>
			</div>
		});
		*/
		const lent 		= posts.map((e,i) =>
		{
			return <div className="row" key={i}>
				<div className="col col-md-12" id={e._id}>
					<Post 
						{ ...e }
						isCurrent={ e._id==this.props.orig }
					/>
				</div>
			</div>
		});
		return <div className="layout-state">
			<div className="layout-state-head">
				<LayoutIcon
					isSVG={ true }
					src={ this.props.route.icon }
					className="layout-state-logo "
				/>
				<div className="layout-state-title">
					{ __( this.props.route.title ) }
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 small my-3 text-danger hidden" >
					{this.props.comment}
				</div>			
				<div className="col-md-12 hidden">
					{__("Context caht of post ") }: <b> {this.props._id}</b>
				</div>			
				<div className="col-md-12 hidden">
					{__("Text of post") }: <b> {this.props.post.post_text}</b> | ID: <b>{this.props.post._id}</b>
				</div>			
				<div className="col-md-12 hidden">
					{__("ID of sourse chat") }: <b> {this.props.sourse._id}</b>
				</div>				
				<div className="col-md-12 hidden">
					{__("Repost ID") }: <b> {this.props.orig._id}</b>
				</div>					
				<div className="col-md-12 ">
					<div className=" p-4">
						{/*
						<div className="lead text-center text-dark">
							{source.title}
						</div>
						<CategoryForm 
							{...source}
							editable={false}
							ID={source._id }							
							data={source}	
							data_type={"Source"}
							isOpen={ true } // for Yandex Maps
							isHiddenSave={true}
							isHiddenClose={true}
						/>
						*/}
						<ChatHead {...source} />
					</div>
				</div>				
				<div className="col-md-12">
					{lent}
				</div>			
			</div>
		</div>
	}
}

export default compose(
	graphql(getSourcePostLog, 
	{
		 options: props => {
			return {				
				variables: {
					post_id: props._id
				}
			}
		},
		props: ({ data }) => ({
			loadingSRC: data.loading, 
			getSourcePostLog: data.getSourcePostLog
		})
	}),
	withApollo,
	withRouter
)(ChatListState);