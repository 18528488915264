import React, {Fragment, Component} from "react";
import {__} from "../../utilities/i18n";
import LayoutIcon from "../../layout/LayoutIcon";
import {AppToaster} from "../../utilities/blueUtils";
import { Intent } from "@blueprintjs/core";
import layouts from "../../layouts";
import {form} from "../../layout/DataTable/form";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';


export default class Profile extends Component
{
	state = this.props.user;
	render()
	{
		console.log( layouts.schema.UserCurrentInput );
		const fields = Object.keys(layouts.schema.UserCurrentInput.apollo_fields);
		const flds = fields
			.filter( e => e !== "_id" && !layouts.schema.UserCurrentInput.apollo_fields[e].hidden )
				.map((e, i) =>
				{
					const {component, editable, values } = layouts.schema.UserCurrentInput.apollo_fields[ e ];
					return <Fragment key={i}>
						{
							form(
								e,
								this.state[e] || "",
								this.onEditField,
								layouts.schema.UserCurrentInput.apollo_fields[ e ].type,
								{
									className: "form-control input light height_auto", 
									component2: [ 
										{ _id:"subscriber", title:"subscriber"}, 
										{ _id:"admin", 		title:"admin" }, 
									],
									values: Array.isArray(values) ? values 
										.map( e =>
										{
											return {_id:e, title:e};
										}) : null,
									editable:  editable
								},
								layouts.schema.UserCurrentInput.apollo_fields[ e ]
							)
						}
					</Fragment>;
				});
		return <div className="layout-center11">
			<div className="row">
				<div className="col-md-6">
					
						{flds}
					
					
					<div className="row">
						<div className="col-md-4 layout-label">
							
						</div>
						<div className="col-md-8 mt-3">
							<div className="btn btn-secondary btn-sm px-5" onClick={this.onEdit}>
								{__("Save")}
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>;
	}
	onEditField = ( value, fieldtype, title) =>
	{
		let state = {...this.state};
		state[ title] = value;
		this.setState(state);
		console.log(value, title, state);
	}
	onEditFiels= evt =>
	{
		const fieldtype = evt.currentTarget.getAttribute("fieldtype");
		const value  = evt.currentTarget.value;
		//console.log(fieldtype, value);
		let state = {...this.state};
		state[fieldtype] = value;
		this.setState(state);
	}
	onEdit = () =>
	{

		let state = {...this.state};
		// console.log(state);
		delete state._id;
		this.props.onChange( state );
	}
}