import React, {Fragment} from "react";
import BasicState from "./BasicState";
import layouts from "../layouts";
import {__} from "../utilities/i18n";
import Loading from "../utilities/Loading";
import { graphql, compose, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import Moment from 'react-moment';
import moment from 'moment';
import $ from "jquery";
import {withRouter} from "react-router";
import { NavLink } from 'react-router-dom';
import { AnchorButton, Button, Classes, Dialog, Intent, Tooltip } from "@blueprintjs/core";
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const getUsers = loader("../graphql/getUsers.graphql");
const setModerator = loader("../graphql/setModerator.graphql");

class ModeratorsState extends BasicState
{
	
	getRoute = () =>
	{
		return "moderators";
	}
	addRender()
	{
		if( this.props.loadingUsers )
			return <Loading />;
		let users = this.props.users || [];
		
		let th = this;
		users = users
				.map((e, i)=>
		{
			return <div className="row" key={i} >
				<div className="col-md-4">
					{e.name} {e.middle_name} {e.family_name}
				</div>
				<div className="col-md-4">
					{ e.roles.includes("moderator") ? __("Moderator") : __("Not moderator") }
				</div>
				<div className="col-md-4 bp3-dark">
					{(()=>{
						if (e.roles.includes("moderator")) {
							return <Button onClick={()=>{
								th.props.setModerator.mutate({
									variables: {
										user_id: e._id,
										enabled: false,
										update: ()=>{th.setState(th.state)}
									}
								});
							}}>
								{ __("Unset moderator") }
							</Button>
						}
						else {
							return <Button onClick={()=>{
								th.props.setModerator.mutate({
									variables: {
										user_id: e._id,
										enabled: true,
										update: ()=>{th.setState(th.state)}
									}
								});
							}}>
								{ __("Set moderator") }
							</Button>
						}
					})()}
				</div>
			</div>
		});
		
		return <div>
			{users}
		</div>;
	}
}
export default compose(
    graphql(getUsers, 
	{
		options: ( props ) => ({
			variables: { },
			name: "getUsers"
		}),
		props: ( p ) => { 
			const data = p.data;
			// console.log("getPlaces", p);
			return {loadingUsers: data.loading, users: data.getUsers}},
	}),
	graphql(setModerator, 
	{
		options: ( props ) => ({
			variables: { },
			name: "setModerator"
		}),
		props: ( p ) => { 
			return {setModerator: p}
			const data = p.data;
			// console.log("getPlaces", p);
			return {loadingUsers: data.loading, user: data.setModerator}},
	}),
	withApollo,
	withRouter
)(ModeratorsState);