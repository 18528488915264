import React, {Component, Fragment} from "react";
import {__} from "../../utilities/i18n";
import Loading from "../../utilities/Loading";
import Moment from 'react-moment';
import moment from 'moment';
import $ from "jquery";
import {withRouter} from "react-router";
import {String, URL, DateTime, Radio, Geo, TextField } from "../../layout/DataTable/form";
import CategoryForm from "../../layout/DataTable/single/CategoryForm";
import { AnchorButton, Button, ButtonGroup, Classes, Dialog, Intent, Tooltip, FormGroup, InputGroup, TextArea } from "@blueprintjs/core";
import {compose} from "recompose";
import {graphql, withApollo} from "react-apollo";
import {loader} from "graphql.macro";

class NewPlaceForm extends Component
{
	state={ ...this.props };
	render()
	{
		let th = this;
		if (this.props.geo != this.state.last_geo) {
			if (this.state.address) {
				th.state.address = "";
				th.setState(th.state, ()=>{th.props.on("address", "");});
			}
			window.ymaps.geocode( this.props.geo || this.state.new_mark_coords, { results:1, kind:"house" })
				.then(function (res) 
				{
					const firstGeoObject = res.geoObjects.get(0);
					const adress =  firstGeoObject.getLocalities().length
						? firstGeoObject.properties.get('metaDataProperty').GeocoderMetaData.AddressDetails.Country.AddressLine
						: firstGeoObject.getAdministrativeAreas()
					th.state.address = adress;
					th.state.last_geo = th.props.geo;
					th.setState(th.state, ()=>{th.props.on("address", adress);});
				})
		}
		//console.log(this.props.place_types);

		const place_types = this.props.place_types;
		let state = { ...this.state, geo: this.state.geo || this.state.new_mark_coords }
		return <section>
			{
			}
			<CategoryForm 
				{...state}
				ID={null}							
				data={ state }	
				data_type={ "Place" }						
				on={this.onChange}
				onChange={this.onChange}
				// onSave={(state, _id) => this.onSave( state, _id)}
				onDelete={this.onDelete}
				onClose={ this.onClose }
				vertical={true}
				isHiddenSave={true}
				isHiddenClose={true}
				isHiddenDelete={true}
				isOpen={ true }
			/>
		</section>
	}	
	onChange=(field, value, id) =>
	{
		console.log(field, value, id);
		let state = {...this.state};
		state[value] = field;
		this.setState( state, function()
		{
			console.log( this.state );
			this.props.on(field, value);
		});
	}
}

export default NewPlaceForm;