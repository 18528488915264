import React, {Component,Fragment} from "react";
import { Popover, Menu, MenuDivider, MenuItem,Button, Intent, Card } from "@blueprintjs/core";
import {__} from "../../utilities/i18n";
import Moment from 'react-moment';
import moment from 'moment';

export default class Event extends Component
{
	render()
	{
		let clss = "", type="", tp= __( "Event");
		//console.log( this.props );
		switch(this.props.external_system)
		{
			case "wp_fest":
				clss = "wp-fest";
				type = __("Event created by WP-Fest service");
				tp = __( "Festival" );
				break;
			case "timepad":
				
				type = __("Event created by TimePad service");
				tp = __( "Event");
				break;
			default:
				type = __("");
				break;
		}
		const start_date = this.props.start_date ? moment(this.props.start_date ).format('D.MM.YYYY HH:mm') : __("Date not defined");
		const end_date = this.props.end_date ? moment(this.props.end_date ).format('D.MM.YYYY HH:mm') : __("Date not defined");
		return <Card interactive={false} className={"p-2 event-card " + clss}>
			<div className="event-card-header">
				<div className="logo"/>
			</div>
			<div className="p-2 ">
				<div className="pre-title">
					{tp}
				</div>
				<div className="event-card-title">
					{this.props.title}
				</div>
			</div>
			<div className="p-2">
				{this.props.description}
			</div>
			<div className="p-2">
				{this.props.place.title}
			</div>
			<div className="afisha-event-date">
				{start_date} - {end_date}
			</div>
			<div className="afisha-event-type">
				{type}
			</div>
		</Card>
	}
}