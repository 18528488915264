import React, {Component, Fragment} from "react";
import User from "./User";
import $ from "jquery";
import {__} from "../utilities/i18n";
import LayoutHelp from "./LayoutHelp";
import LayoutBells from "./LayoutBells";
import LayoutComments from "./LayoutComments";
import LayoutHeaderMenu from "./LayoutHeaderMenu";
import layouts from "../layouts";
import LayoutIcon from "./LayoutIcon";
import { NavLink } from 'react-router-dom';
import { Popover, Menu, MenuDivider, MenuItem,Button, Intent } from "@blueprintjs/core";



class LayoutHeader extends Component
{
	state = { isOpen:false, height:0, current:this.props.current, logged:this.props.logged, isHumburger:false }
	componentWillReceiveProps (nextProps )
	{
		this.setState({
			current: nextProps.current,
			logged: nextProps.logged
		});
	}
	render()
	{
		const help = layouts.help && layouts.help.length > 0 ? <LayoutHelp /> : null;			
		const bells = layouts.bells && layouts.bells.length > 0 ? <LayoutBells /> : null;
		const comments = layouts.comments && layouts.comments.length > 0 ? <LayoutComments /> : null;
		const links = layouts.link.map((e, i) => {
			return <a href={e.route} key={i} title={e.title} className="layout-header-link">
				<LayoutIcon
					src={ e.icon }
					className="layout-header-icon"
				/>				
				<span>
					{e.title}
				</span>
			</a>
		});
		return <div className="layout-header" >
			<div className="layout-left">
				<NavLink to="/" style={{ display:"flex" }}>
					<div 
						className="layout-header-icon " 
						style={{ 
							backgroundImage:"url(" + layouts.icon + ")",
							height: layouts.icon_height ? layouts.icon_height : 30,
							width: layouts.icon_width ? layouts.icon_width : 30,
						}}
					/>
					<div className="layout-header-title">
						{layouts.name}
					</div>
				</NavLink>
				<div className="humburger" onClick={this.onHumburger}>
					<i className={"fas " + (this.state.isHumburger ? "fa-times" : "fa-bars")} />
				</div>
			</div>
			<div className={ "d-flex-menu " + (this.state.isHumburger ? "open": "" ) }>
				<LayoutHeaderMenu onHumburger={this.onHumburger}/>
			</div>
			<div 
				className={ "layout-menu-right " + (this.state.isHumburger ? "open": "" ) } 
				id="layout-menu-right"
			>
				{links}
				{help}
				{bells}
				{comments}
				<User
					current={ this.state.current }
					onCurrent={ this.props.onCurrent }	
					onLogout={ this.props.onLogout }
					loginned={ this.state.logged }
					avatar={ layouts.user.avatar }
					isOpen={ window.screen.width < 600 }
				/>
			</div>
		</div>
	}
	onHumburger = () =>
	{
		var totalHeight = 0;
		$("#layout-menu-right").children().each(function(){
			totalHeight += $(this).outerHeight(true); // true = include margins
		});
		console.log( totalHeight );
		this.setState({isHumburger:!this.state.isHumburger, totalHeight: this.state.isHumburger ? totalHeight : 0 });
	}
}
export default LayoutHeader;