import React from "react";
import BasicState from "./BasicState";
import {__} from "../utilities/i18n";

class ConfigState extends BasicState
{
	basic_state_data() 
	{
		return {telegram_token : "--" }
	}
	myState = () =>
	{
		return <div className="row">
			<div className="col-md-3 layout-label">
				{__("telegram token")}
			</div>
			<div className="col-md-7">
				<input 
					type="text"
					className="form-control input" 
					value={this.state.telegram_token} 
					onChange={this.onTelegram}
				/>
			</div>
			<div className="col-md-3 layout-label">
				
			</div>
			<div className="col-md-8 ">
				<div className="btn btn-secondary btn-sm px-5" onClick={this.onEdit}>
					{__("Edit")}
				</div>
			</div>
		</div>
	}
	getRoute = () =>
	{
		return "configuration";
	}
	onTelegram = evt =>
	{
		this.setState({telegram_token:evt.currentTarget.value});
	}
	onEdit = () =>
	{
		
	}
}
export default ConfigState;