import React, {Component, Fragment} from "react";
import {compose} from "recompose";
import CurrentUser from "../../data/CurrentUser";
import {Router, Route, Switch, Redirect, withRouter} from 'react-router';
import layouts from "../../layouts";
import BasicState from "../../mainStates/BasicState";
import DataTableState from "../../mainStates/DataTableState";
import IncomeState from "../../mainStates/IncomeState";
import ProfileState from "../../mainStates/ProfileState";
import SocialsState from "../../mainStates/SocialsState";
import HelpState from "../../mainStates/HelpState";
import ConfigState from "../../mainStates/ConfigState";
import MapState from "../../mainStates/MapState";
import ContextChatState from "../../mainStates/ContextChatState/index";
import RegisterState from "../../mainStates/RegisterState";
import NoMatchState from "../../mainStates/NoMatchState";
import SettingsState from "../../mainStates/SettingsState";
import HTMLState from "../../mainStates/HTMLState";
import HTMLSourceState from "../../mainStates/HTMLSourceState";
import TeamsMindState from "../../mainStates/TeamsMindState";
import CalendarState from "../../mainStates/CalendarState";
import LoginState from "../../mainStates/LoginState";
import PlaceState from "../../mainStates/PlaceState";
import AfficheState from "../../mainStates/AfficheState";
import ModeratorsState from "../../mainStates/ModeratorsState";

class LayoutMain extends Component
{
	components = {
			"IncomeState" : IncomeState,
			"BasicState" : BasicState,
			"SocialsState" : SocialsState,
			"HelpState" : HelpState,
			"ConfigState" : ConfigState,
			"ProfileState" : ProfileState,
			"MapState" : MapState,
			"ContextChatState" : ContextChatState,
			"RegisterState" : RegisterState,
			"NoMatchState" : NoMatchState,
			"SettingsState" : SettingsState,
			"HTMLState" : HTMLState,
			"HTMLSourceState" : HTMLSourceState,
			"CalendarState" : CalendarState,
			"LoginState" : LoginState,
			"TeamsMindState" : TeamsMindState,
			"PlaceState" : PlaceState,
			"AfficheState" : AfficheState,
			"ModeratorsState" : ModeratorsState,
		}
	render()
	{
		//console.log( CurrentUser.intersectionRole( ['admin'] ));
		let routers = [], overs = [], grands = [];
		layouts.menu
			.concat(layouts.main_menu)
				.concat(layouts.extended_routes)
					.concat(layouts.profile)
						.concat(layouts.help)
							.concat(layouts.bells)
								.concat(layouts.comments)
									.forEach((e, i) =>
		{
			if(e.children && e.children.length > 0) {
				e.children.forEach( ( elem, n ) => {
					if(elem.children && elem.children.length > 0) {
						elem.children.forEach((element, nn) => grands.push( this.searchRouteData(nn, e, elem, element )) );
					}
					overs.push( this.searchRouteData(n, e, elem ) );
				});
			}
			routers.push( this.searchRouteData(i, e ) );
		});
		let main = layouts.extended_routes.filter(e => e.route === "")[0];
		if(!main)
		{
			main = layouts.menu[0];
		}		
		// 
		return <Switch>	
			{ this.searchRouteData(0, main, null, null, "") }
			{ grands }
			{ overs }
			{ routers }
			
			<Route component={NoMatchState} />
			
			{ /*
			<Route
				exact    
				path={'/home'}
				component={<MapState />}
				key={"spaslimir"}
			/>
			<Route   
				path='/mapyy2'
				exact 
				render = { routeProps => (
					<div 
						style={{ width:330, height:330, backgroundColor:'red', position:"absolute", top:100, left:100, zIndex:1000, display:"flex", justifyContent:"center", alignItems:"center", color:"#FFF",  }} 
					>	
						HALLO! WORLD!
					</div>
				)}
			/>
			*/ }
			
		</Switch>
	}
	/* 
		@i - (string | int)key index
		@e - (object)- layouts.json element (in menu, profile, extended_routes, bells, comments, help)
		@elem - (object) child of @e
		@element - (object) child of @elem
		@forceRoute - routee's URL forced up the object.route
		return Route 
	*/
	searchRouteData( i, e=undefined, elem=undefined, element=undefined, forceRoute=undefined )
	{
		//console.log( i, e, elem, element );		
		let preroute, route, routeBasic, noexact_route, currentE;
		if(element)
		{
			//console.log(element.capability);
			if(
				element.capability && 
				Array.isArray(element.capability) && 
				CurrentUser.intersectionRole(element.capability).length == 0
			)
			{
				return;
			}
			preroute 	  = '/' + e.route +  '/' + elem.route;
			route		  = typeof forceRoute !== "undefined" ? forceRoute : element.route + "";
			noexact_route = typeof forceRoute !== "undefined" ? forceRoute : element.route + "/:id";
			currentE = element;
		}
		else if(elem)
		{
			//console.log(elem.capability);
			if(elem.capability && Array.isArray(elem.capability) && CurrentUser.intersectionRole(elem.capability).length == 0)
			{
				return;
			}
			preroute 	= '/' + e.route;
			route		= typeof forceRoute !== "undefined" ? forceRoute : elem.route;
			noexact_route = forceRoute ? forceRoute : elem.route + "/:id";
			currentE = elem;
		}
		else
		{
			if(e.capability && Array.isArray(e.capability) && CurrentUser.intersectionRole(e.capability).length == 0)
			{
				return;
			}
			preroute 	= "";
			route		= typeof forceRoute !== "undefined" ? forceRoute : e.route;
			noexact_route = forceRoute ? forceRoute : e.route + "/:id";
			currentE = e;
		}
		
		const is =   	preroute + '/' +  route 		== this.props.location.pathname 
					|| 	preroute + '/' +  route + "/" 	== this.props.location.pathname;
		if( currentE.component )
		{
			const Component = this.components[currentE.component];
			return [
				<Route 
					exact    
					path = { preroute + '/' +  noexact_route }
					component = { Component }
					key={i + "_1"}
				/>,
				<Route 
					exact    
					path = { preroute + '/' +  route }
					render = { routeProps => (
						<Component
							 onChangeStyle={ style => this.props.onChangeStyle( style ) } 
						/>
					)}
					key={i + "_2"}
				/>
			]
		}
		else if( currentE.html_source )
		{
			return <Route 
				exact  
				path = { preroute + '/' +  route }
				render = { routeProps => (
					<HTMLSourceState
						{ ...currentE }
					/>
				)}
				key={i}
			/>
		}
		else if( currentE.html )
		{
			return [
				<Route 
					exact  
					path = { preroute + '/' +  route }
					render = { routeProps => (
						<HTMLState
							{...currentE}
						/>
					)}
					key={i + "_2"}
				/>
			]
		}
		else if( currentE.data_type)
		{
			//console.log( preroute + '/' +  noexact_route );
			return [				
				<Route 
					exact  
					path = { preroute + '/' +  noexact_route }
					render = { routeProps => (
						<DataTableState
							title={currentE.title}
							icon={currentE.icon}
							data_type={currentE.data_type}
							route={ preroute + '/' + route  }
							is_list={ currentE.is_list  }
						/>
					)}
					key={i + "_2"}
				/>,
				<Route 
					exact  
					path = { preroute + '/' +  route }
					render = { routeProps => ( 
						<DataTableState
							title={currentE.title}
							icon={currentE.icon}
							data_type={currentE.data_type}
							route={preroute + '/' + route }
							is_list={ currentE.is_list  }
						/>
					)}
					key={i + "_1"}
				/>
			]
		}
		return;
		return <Route 
			exact  
			path = { preroute + '/' +  route }
			render = { routeProps => (
				<NoMatchState
					title={"404"}
					icon={""}
				/>
			)}
			key={i}
		/>;
	}
}
export default compose(
	withRouter
)(LayoutMain);