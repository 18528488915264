import React, {Component, Fragment} from "react";
import BasicState from "./BasicState";

class HelpState extends BasicState
{
	myState=()=>
	{
		return "HelpState";
	}
	getRoute = () =>
	{
		return "help";
	}
}
export default HelpState;