import React, {Component, Fragment} from "react";
import CategoryForm from "./single/CategoryForm";
import {getQueryArgs, getMutation, getDelete} from "./form";
import RecieverForm from "./single/RecieverForm";
import layouts from "../../layouts";
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import {__} from "../../utilities/i18n";
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup 
 } from "@blueprintjs/core";
import $ from "jquery";

import { compose, mapProps } from "recompose";
import { loader } from 'graphql.macro';
import {graphql, Query, withApollo, Mutation} from 'react-apollo';
import gql from "graphql-tag";
import {withRouter} from "react-router";


class Row extends Component
{
	state = {
		...this.props.elem,
		current: this.props.current,
		height: this.props.height,
		isOpen: this.props.isOpen,
		allChecked 	: false, 
		checked 	: 0, 
	}
	componentWillReceiveProps ( nextProps )
	{
		if(typeof nextProps.isOpen !== "undefined" )
		{
			this.setState({isOpen:nextProps.isOpen});
		}
		if(typeof nextProps.checked !== "undefined" )
		{
			this.setState({checked:nextProps.checked});
		}
		if(typeof nextProps.allChecked !== "undefined" )
		{
			this.setState({allChecked:nextProps.allChecked});
		}
	}
	render()
	{
		const { trColor, data_type} = this.props;
		//console.log( this.props.location.pathname, this.props.route + "/" + this.state._id );	
		/* MUTATION change start */
		// генерируем запрос на изменение из полей схемы типа dанных
		//const apollo_fields = layouts.schema[ data_type ].apollo_fields;
		//const flds = getQueryArgs(data_type);		
		//const mutation_change = "mutation m_change($id:ID $input:" + data_type +"Input){ change" + data_type +"(id: $id, input: $input){ " + flds + " }}";
		const mutation_delete = getDelete(data_type);
		const mutation_change = getMutation(data_type);
		let mutation_change_gql = gql`${mutation_change}`;

		return <Mutation mutation={mutation_change_gql}>
			{( m_change, { data } ) => {
				return (<Mutation mutation={mutation_delete}>
					{( m_delete, { data } ) => this.onRowForm(m_change, m_delete)}
				</Mutation>)
				}
			}
		</Mutation>;

		/* MUTATION change end */
	}
	onRowForm = ( m_change, m_delete ) =>
	{
		const { trColor, data_type} = this.props;
		const style = { backgroundColor : trColor  };
		
		const isOpen = (this.props.location.pathname == this.props.route + "/" + this.state._id) || (this.props.location.pathname == this.props.route + "/new" && this.props.isNew)

		let CardForm = CategoryForm;
		const forms = {
			"CategoryForm" : CategoryForm,
			"RecieverForm" : RecieverForm, // edit inline children no scalar Data Type elements (marked "recieved" in scheme)
		};
		if(layouts.schema[ data_type ].card_component)
		{
			CardForm = forms[ layouts.schema[ data_type ].card_component ];
		}
		
		let html = [];
		if (this.props.location.pathname == this.props.route || isOpen){
			html.push(
					<tr >
					{
						this.rows().map( (e, i) => {
							return this.props.isNew 
								?
								<th col={ e[0] } key={i} width={e[2]} style={style}>{ e[1] }</th>
								:
								<td key={i}>
									{ this.onDefRow(e[0], this.state, e )}
								</td> 
						})
					}
					</tr>
			);
		};
		if (isOpen) {
			console.log(this.props.isNew);
			html.push(
				<tr>
					<td colSpan={12}>
						<Card 
							fill={"true"} 
							className={isOpen ? "layout-data-card p-4 mb-1" : "layout-data-card py-0 px-4"} 
							style={{ height: this.state.height }}
							interactive={false} 
						>
							<CardForm 
								{...this.state}
								ID={this.state._id}							
								data={ this.state }	
								data_type={ data_type }						
								onChange={this.onChange}
								onSave={(state, _id) => this.onSave(m_change, state, _id)}
								onDelete={(_id) => this.onDelete(m_delete, _id)}
								onClose={ this.onClose }
								saveLabel={this.props.isNew ? __("Save") :__("Update")}
								isNew={this.props.isNew}
								isOpen={ isOpen } // for Yandex Maps
								ref={(node) => {this.card = node }}
							/>
						</Card>
					</td>
				</tr> 
			);
		}
		
		return <Fragment>
				{html}
			</Fragment>
	}
	onSave =(m_change, state, _id)=>
	{		
		delete state.current;
		delete state.height;
		delete state.isOpen;
		delete state.allChecked;
		delete state.checked;
		//Удаляем не-редактируемые поля, которые всё равно запретит сервер
		const apollo_fields = layouts.schema[ this.props.data_type ].apollo_fields;
		for (let i in apollo_fields)
		{
			if(!apollo_fields[i].editable)
				delete state[i];
		};
		//
		
		//TODO: абстрагировать graphql-запросы
		const aq = layouts.schema[ this.props.data_type ].apollo_query;
		//const apollo_fields = layouts.schema[ this.props.data_type ].apollo_fields;
		const fl = getQueryArgs(this.props.data_type);
		const query = "query "+aq+" { "+aq+" { "+fl+" } }";
		//console.log("query");
		//console.log(query);
		let query_gql = gql`${query}`;
					
		//console.group('Row.onSave');
		//console.log(state, _id);
		//console.groupEnd();
		m_change({
			variables:
			{
				"id": _id, 
				"input": state
			},
			update: (store, { data: data }) =>
			{
				console.log(data);
				const respData = data["change" + this.props.data_type];
				if(respData)
				{
					//console.log(state);
					//console.log(respData);
					let _state = {};
					for(let e in respData)
					{
						_state[e] = respData[e];
					}
					//console.log(_state);
					this.setState( _state );
					
					const data1 = store.readQuery({query: query_gql, variables: {}  });
					const new_state = { ...state, ..._state, _id: respData._id};
					data1[ aq ][ this.props.i ] = new_state;
					//console.log("data1:", data1);
					//console.log(new_state)
					store.writeQuery({ query: query_gql, variables: {}, data: data1 });
					this.props.onOpen(-1);
				}
			},
			refetchQueries: [ { query: query_gql, variables: {}}]
		});
	}

	onDelete = (m_delete, _id) =>{


		//TODO: абстрагировать graphql-запросы
		const aq = layouts.schema[ this.props.data_type ].apollo_query;
		const fl = getQueryArgs(this.props.data_type);
		const query = "query "+aq+" { "+aq+" { "+fl+" } }";
		let query_gql = gql`${query}`;

		m_delete({
			variables:
				{
					"id": _id,
				},
			update: (store, { data: data }) =>
			{
				console.log(data);
				const respData = data["delete" + this.props.data_type];
				if(respData)
				{
					this.props.onOpen(-1);
				}
			},
			refetchQueries: [ { query: query_gql, variables: {}}]
		});

		// this.props.onOpen(-1);
	}

	onDefRow( col, elem, dat )
	{

		let txt;
		switch(col)
		{
			case "edit":
				txt = this.props.location.pathname !== this.props.route + "/" + this.state._id
					?
					<NavLink
						to={{
							pathname: this.props.route + "/" + this.state._id
						}}
					>
							<Button
								title={__("Edit")}
								icon="edit"
								intent={Intent.SUCCESS}
								fill={true}
							/>
					</NavLink>
					:
					<NavLink
						to={{
							pathname: this.props.route
						}}
					>
						<Button
							title={__("Close")}
							icon="chevron-up"
							intent={Intent.SUCCESS}
							fill={true}
						/>
					</NavLink>
				break;
			default:
				txt = this.onRow(col, this.state, dat );
		}
		return txt;
	}
	onRow(col, elem, dat )
	{
		let txt;		
		switch(col)
		{
			case "ch":
				txt = <Fragment>
					<label className="_check_blue_ ml-2">
						<input 
							type="checkbox" 
							eid={this.state._id}
							value={this.state._id}
							checked={ this.state.checked } 
							onChange={this.onChecked}
						/>
					</label>
				</Fragment>
				txt = null;
				break;
			case "_id":
				txt = <div 
					className="px-1" 
					title={this.state._id} 
					style={{
						maxWidth:100, 
						textOverflow: "ellipsis", 
						overflow: "hidden", 
						whiteSpace: "nowrap"
					}}
				>
					{this.state._id}
				</div>
				break;
			default:
				switch( layouts.schema[this.props.data_type].apollo_fields[col].type )
				{
					case "rgb":
						txt = <div className="d-flex" style={{alignItems:"center"}}>
							<div 
								style={{ width:14, height:14, borderRadius: 2, backgroundColor: this.state[col] }} 
								className="mr-2"
							/>
							{this.state[col]}
						</div>;
						break;
					case "date":
						txt = this.state[col] 
						?
						<Moment locale="ru" format="D MMMM YYYY">
							{new Date( this.state[col] )}
						</Moment>
						:
						__("Date not defined")
						break;
					case "geo":
						txt = <div className="small opacity_5">
							<div>{this.state[col] ? this.state[col][0] : null}</div>
							<div>{this.state[col] ? this.state[col][1] : null}</div>
						</div>; 
						break;
					case "boolean":
						txt = <div className="text-center">
							<i className={this.state[col] ? "fas fa-chevron-down text-success" : "fas fa-times text-danger"} />
						</div>; 
						break;
					case "checkbox":
						txt = Array.isArray(this.state[col]) ? this.state[col].map((e, i) =>
						{
							const elem = typeof e === "string" ? {_id:e, title:e} : e;
							return <Tag >{elem.title}</Tag>
						}) : this.state[col].toString();
						break;
					case "color":
						txt = <div style={{
							width:17,
							height:17,
							backgroundColor:this.state[col],
							border: "1px solid #00000020",
							outline: "1px solid #00000020",
							outlineOffset:2
						}} />
						break;
					case "external":
						const vv = layouts.schema[dat[3].component].visibled_value 
							? layouts.schema[dat[3].component].visibled_value 
							: "title";
						txt = this.state[col] ? this.state[col][vv] : null;
						break;
					default:
						txt = this.state[col];
				}
		}
		return txt;
	}
	rows()
	{
		const { data_type } = this.props;
		let tabs = [];
		let i = 0;
		for(let e in layouts.schema[data_type].apollo_fields)
		{
			if(!layouts.schema[data_type].apollo_fields[e].thread) continue;
			let ttl, w;
			switch(e)
			{
				case "_id":
					ttl	= <Tooltip intent={Intent.DANGER} content={__("id")}>{__("id")}</Tooltip>;
					w 	= 30;
					break;
				default:
					ttl = __(layouts.schema[data_type].apollo_fields[e].title);
					w 	= "auto"
			}
			tabs.push([ e, ttl, w, layouts.schema[data_type].apollo_fields[e] ]);
		};
		
		/*
		tabs.unshift([
			'ch', 
			<label className="_check_red_ ml-2">
				<input type="checkbox" checked={this.state.allChecked} onChange={this.onAllChecked} />
			</label>, 
			50
		]);
		*/
		//					<Tooltip intent={Intent.DANGER} content={__("Add new")} key={1}>
		// 						<div className="btn btn-link text-light btn-sm" onClick={this.onEditForm}>
		// 							<Icon icon="plus" />
		// 						</div>
		// 					</Tooltip>

		//					<Tooltip intent={Intent.DANGER} content={__("Cancel")} key={1}>
		// 						<div className="btn btn-link text-light btn-sm" onClick={this.onClose}>
		// 							<Icon icon="undo" />
		// 						</div>
		// 					</Tooltip>
		tabs.unshift(['edit',	
			[
				!this.state.isOpen ?
					<Button
						icon="plus"
						intent={Intent.DANGER}
						fill={true}
						onClick={this.onEditForm}
						title={__("Add new")}
						key={1}
					/>
					:
					<Button
						icon="undo"
						intent={Intent.DANGER}
						fill={true}
						onClick={this.onClose}
						title={__("Cancel")}
						key={1}
					/>,
				<span key={21}>{this.row_edit()}</span>
			], 30
		]);
		return tabs;
	}
	
	row_edit()
	{
		return <span> </span>
	}
	
	onAllChecked = evt =>
	{
		const checked = evt.currentTarget.checked ? 1 : 0;
		this.props.onAllChecked( checked );
	}
	onChecked = evt =>
	{
		const checked = evt.currentTarget.checked ? 1 : 0;
		this.props.onChecked(checked, this.props.elem._id);
	}
	onEditForm = evt =>
	{
		//const btn = evt.currentTarget
		//const par = $(btn).parents("tr").next().find(".category_card");
		//const h = $(par).height();
		//this.setState({ isOpen:true, current:tid, isNew:false, height: h + 45 });
		this.props.onOpen(this.props.i);
	}



	onClose = () =>
	{
		//this.setState({ isOpen:false, current : -1, isNew : false });
		this.props.onOpen(-1);
	}
	onChange=(field, value, id) =>
	{
		//console.log(field, value, id);
		this.setState({ field: value });
	}
}

export default compose(
	withApollo,
	withRouter
)(Row);