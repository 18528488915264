import React, {Component, Fragment} from "react";
import BasicState from "./BasicState";

class IncomeState extends BasicState
{
	myState=()=>
	{
		return "IncomeState";
	}
	getRoute = () =>
	{
		return "income";
	}
}
export default IncomeState;