import React, {Component, Fragment} from "react";
import BasicState from "./BasicState";
import LayoutLogin from "../layout/LayoutLogin";

import { compose } from "recompose";
import { loader } from 'graphql.macro';
import {graphql, Query, withApollo} from 'react-apollo';
import {withRouter} from "react-router";

const l_authorize = loader("../graphql/authorize.graphql");

class LoginState extends BasicState
{
	myState=()=>
	{
		return <LayoutLogin onLogin={this.onLogin} />;
	}
	getRoute = () =>
	{
		return "login";
	}
	onLogin = (login, password) =>
	{
		
		this.props.onLogin( login, password );
		return;
		
		this.props.authorize({
			variables:
				{"input": {
						"assertion": process.env.REACT_APP_ASSERTION,
						"user_code": password || "",
						"login_hint": login || "",
						"scope": [""]
					}
				},
			update: (store, {data: {authorize}} ) =>
			{
				console.log(authorize);
				if(authorize.auth_req_id)
				{
					this.setState({ logged:1, auth_req_id: authorize.auth_req_id });
				}
			}
		});
	}
}
export default compose(

	graphql(l_authorize, {"name": "authorize"}),
	withApollo,
	withRouter
)(LoginState);