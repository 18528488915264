import React, {Component} from "react";
import {__} from "../../../utilities/i18n";
import { Tag, ButtonGroup, Button, Intent } from "@blueprintjs/core";
import { NavLink } from 'react-router-dom';
import Form from "./Form";

export default class Link extends Form
{
	isEnabled()
	{
		const {field, title, value, extended_link, external_link_data } = this.props;
		return <NavLink
			to={"/" + field}
			className="m-1"
		>
			{ value }
		</NavLink>;
	}
	isDesabled()
	{
		return this.isEnabled();
	}
}