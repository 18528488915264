import React, {Fragment} from "react";
import BasicState from "./BasicState";
import layouts from "../layouts";
import {__} from "../utilities/i18n";
import Loading from "../utilities/Loading";
import { graphql, compose, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import Moment from 'react-moment';
import moment from 'moment';
import $ from "jquery";
import {withRouter} from "react-router";
import { NavLink } from 'react-router-dom';
import Event from "./afficheState/Event";
import EventsFilters from "./afficheState/EventsFilters";
import { AnchorButton, Button, Classes, Dialog, Intent, Tooltip } from "@blueprintjs/core";

const getPlaces = loader("../graphql/getPlaces.graphql");
const getPlaceEvents = loader("../graphql/getPlaceEvents.graphql");
const getTeams = loader("../graphql/getTeams.graphql");

class AfficheState extends BasicState
{
	
	getRoute = () =>
	{
		return "affiche";
	}
	addRender()
	{
		if( this.props.loadingPlaces || this.props.loadingTeams || this.props.loadingEvents )
			return <Loading />;
		let _events = this.props.events || [];
		
		let events = _events
			.filter(e => typeof e.title == "string" && e.title !== "")
				.map((e, i)=>
		{
			if (!e.place) {
				return;
			}
			console.log( typeof e.title )
			return <div key={i} className="col-lg-4 col-md-4 col-12 my-3" ><Event {...e} /></div>
		});
		
		return <div className="row">
			{events}
		</div>;
	}
}
export default compose(
    graphql(getPlaces, 
	{
		options: ( props ) => ({
			variables: { },
			name: "getPlaces"
		}),
		props: ( p ) => { 
			const data = p.data;
			// console.log("getPlaces", p);
			return {loadingPlaces: data.loading, places: data.getPlaces}},
	}),
	graphql(getPlaceEvents, 
	{
		options: ( props ) => ({
			variables: { },
			name: "getPlaceEvents"
		}),
		props: ( p ) => { 
			const data = p.data;
			//console.log("getPlaceEvents", p);
			return {loadingEvents: data.loading, events: data.getPlaceEvents}		
		},
	}),
	graphql(getTeams, 
	{
		options: ( props ) => ({
			variables: { },
			name: "getTeams"
		}),
		props: ( p ) => { 
			const data = p.data;
			//console.log("getTeams", p);
			return {loadingTeams: data.loading, teams: data.getTeams}		
		},
	}),
	withApollo,
	withRouter
)(AfficheState);