import React, {Component} from "react";
import layouts from "../layouts";
import LayoutIcon from "./LayoutIcon";
import { NavLink } from 'react-router-dom';
import {__} from "../utilities/i18n";
const injectTapEventPlugin = require("react-tap-event-plugin");

class LayoutMenuLeft extends Component
{
	state = {
		current:this.props.current,
		hover:false
	}
	componentWillReceiveProps (nextProps )
	{
		this.setState({
			current: nextProps.current
		});
	}
	render()
	{
		const icons = layouts.menu.map((e,i) =>
		{
			const arrow = e.children && e.children.length > 0 
				?
				<span className="arrow fas fa-caret-right">
				</span>
				:
				null
			return <NavLink
				to={"/" + e.route}
				className={"layout-left-btn " + (i === this.state.current ? " active" : "")} 
				activeClassName="active"
				i={i} 
				key={i} 
			>
				<div className={"layout-menu-icon"} 
					onMouseEnter={() => this.toggleHover()} 
					onMouseLeave={() => this.toggleHover()}		
				>
					<LayoutIcon
						src={ e.icon }
						className="left-menu-icon"
					/>
				</div>
				<div className={ "layout-menu-left-label " +( this.state.hover ? "hover" : null ) }>
					{__(e.title)}
				</div>
				{arrow}
			</NavLink>
		});
		return <div className="layout-menu-left">
			{icons}
			<div className="layout-settings-btn ">
				<NavLink
					to={"/cog"}
					className={ "layout-left-btn " } 
					activeClassName="active"
				>		
					<div className={"layout-menu-icon"} >
						<i className="fas fa-cog" /> 
					</div>
				</NavLink>
			</div>
		</div>
	}
	onSwitch = evt =>
	{
		//this.setState({ current:evt.currentTarget.getAttribute("i") });
		this.props.onCurrent( evt.currentTarget.getAttribute("i") );
	}
	toggleHover()
	{
		this.setState({hover: !this.state.hover})
	}
}
export default LayoutMenuLeft;