import React, {Component, Fragment} from "react";
import Row from "./Row";
import {__} from "../../utilities/i18n";
import layouts from "../../layouts";
import Moment from 'react-moment';
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup 
 } from "@blueprintjs/core";
import $ from "jquery";

export default class DataTableElements extends Component
{
	constructor(props)
	{
		super(props);
		const post = this.props.post ? this.props.post : [];
		let checked= {};
		post.forEach(e => checked[e._id] = 0);
		this.state = {
			post,
			current: this.props.current,
			height: this.props.height,
			allChecked 	: false, 
			checked,
			
		}
	}
	componentWillReceiveProps ( nextProps )
	{
		if(typeof nextProps.current !== "undefined" )
		{
			//console.log(nextProps.current);
			this.setState({current:nextProps.current});
		}
		if(typeof nextProps.checked !== "undefined" )
		{
			//console.log(nextProps.checked);
			this.setState({checked:nextProps.checked});
		}
	}
	render()
	{
		const rows = this.state.post.map((elem, i) => 
		{		
			return <Row
				key={i}
				i={i}
				trColor={this.props.trColor}
				route={this.props.route}
				data_type={this.props.data_type}
				isOpen={i == this.state.current}
				onOpen={this.onOpen}
				onChecked={this.onChecked}
				elem={elem}
				isNew={this.props.isNew}
				checked={ this.state.checked[elem._id] }
			/>
		});
		return <tbody>
			{rows}
		</tbody>
	}
	onOpen = current =>
	{
		this.setState({current});
		this.props.onCurrent(current);
	}
	onChecked = ( chck, _id ) =>
	{
		let checked = {...this.state.checked};
		if(chck)
		{
			checked[ _id ] = 1;
		}
		else
		{
			checked[ _id ] = 0;
		}
		this.setState({checked});
		// console.log( checked, _id );
		// console.log( chck, this.state.post.length );
		this.props.showAllChecked( checked );
	}
	
}