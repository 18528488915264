import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import layouts from "../layouts";
import {__} from "../utilities/i18n";
import { NavLink } from 'react-router-dom';
import {  Position, Toast, Toaster, Intent } from "@blueprintjs/core";
import {AppToaster} from "../utilities/blueUtils";
import CurrentUser from "../data/CurrentUser";

import {compose} from "recompose";
import { Query, withApollo } from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";
import Loading from "../utilities/Loading";
import LayoutIcon from "../layout/LayoutIcon";

class User extends Component
{
	state = { isOpen:this.props.isOpen, height:0, current:this.props.current }
	
	componentWillReceiveProps (nextProps )
	{
		this.setState({
			current: nextProps.current,
			isOpen: nextProps.isOpen,
			height: nextProps.isOpen ? 230 : 0 
		});
	}
	componentDidMount() 
	{
		document.body.addEventListener('click', this.onMouseLeaveHandler);
	}
    componentWillUnmount() 
	{
		document.body.removeEventListener('click', this.onMouseLeaveHandler);
	}
	onMouseLeaveHandler = e =>
	{	
		const domNode = ReactDOM.findDOMNode(this);
		if (!domNode || !domNode.contains(e.target))	
		{
			this.setState({
				isOpen: this.props.isOpen, 
				height: 0
			});
		}
	}
	
	render()
	{
		return this.props.loginned == 2 ? this.logined() : this.unLogined();
	}
	unLogined()
	{
		return <NavLink className="icon-unlogined" to="/login">
			<div className="icon"/>
			<div > {__("Enter")} </div>
		</NavLink>
	}
	logined()
	{
		const lout = layouts.profile.map((e,i) => 
		{
			return <NavLink
				className={ "" } 
				activeClassName="active"
				to={ "/" + e.route }
				key={ i } 
			>
				<LayoutIcon
					isSVG={ true }
					src={ e.icon }
					className="mr-3"
				/>
				{ __(e.title) }
			</NavLink>
		})
		const query1 = "query userInfo {userInfo {_id name family_name roles gender email}}";
		const query_gql1 = gql`${query1}`;
		return <Query query={query_gql1}>{
			({ loading, error, data, client}) => 
			{
				if( loading)
				{
					return <Loading/>;
				}
				if(!data)
				{					
					return	this.unLogined()				
				}
				//console.log(data.userInfo);
				const avatar = this.props.avatar;
				return <Fragment>
					<div 
						className={"icon-logined" + (this.state.isOpen ? " active" : "")}
						onClick={this.onToggle}
					>
						<LayoutIcon
							isSVG={ true }
							src={ avatar }
							className="user-ava"
						/>
						
						<div className="user-name">
							{ 
								data ? data.userInfo.name +" "+ data.userInfo.family_name : "---" 
							}
						</div>
						<div className="chevron-down-light ml-3 mt-1 pointer"></div>	
						<div 
							className="logined-menu" 					
							style={{ height: this.state.height }}
						>
							<ul id="person_menu">
								<NavLink
									className={ "" } 
									activeClassName="active"
									to={"/profile" }
								>								
									<LayoutIcon
										isSVG={ true }
										src={"/assets/img/user.svg" }
										className="mr-3"
									/>
									{__("edit profile")}
								</NavLink>
								{lout}
								<li onClick={this.logout} >								
									<LayoutIcon
										isSVG={ true }
										src={"/assets/img/logout.svg" }
										className="mr-3"
									/>
									{__("logout")}
								</li>
							</ul>
						</div>	
					</div>	
				</Fragment>
			}
		}
		</Query>;
	}
	onToggle = evt =>
	{
		//console.log( document.getElementById("person_menu").clientHeight );
		this.setState({
			isOpen: !this.state.isOpen, 
			height: !this.state.isOpen ? document.getElementById("person_menu").clientHeight : 0 
		});
	}
	logout = () =>
	{
		this.props.onLogout();
	}
}
export default compose(
	withApollo,
	withRouter
)(User);