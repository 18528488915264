import React, {Component} from "react";
import LayoutHeader from "./LayoutHeader";
import LayoutContent from "./LayoutContent";
import LayoutLogin from "./LayoutLogin";
import LayoutScope from "./LayoutScope";
import layouts from "../layouts";
import {AppToaster} from "../utilities/blueUtils";
import {__} from "../utilities/i18n";

import {  
	Intent
 } from "@blueprintjs/core";

import { compose } from "recompose";
import { loader } from 'graphql.macro';
import {graphql, Query, withApollo} from 'react-apollo';
import {withRouter} from "react-router";

const l_token = loader("../graphql/token.graphql");
const l_authorize = loader("../graphql/authorize.graphql");
const l_userInfo = loader("../graphql/userInfo.graphql");

class Layout extends Component
{
	constructor(props)
	{
		super(props);
		const tocken = localStorage.getItem( 'token');
		if(layouts.config && layouts.config.title)
		document.title = layouts.config.title;

		const el	= document.createElement('script');
		el.context	= this;
		el.type 	= 'text/javascript';
		el.src 		= 'https://api-maps.yandex.ru/2.1/?load=package.full&lang=ru_RU&apikey=' + process.env.REACT_APP_YANDEX_MAP_API_KEY;
		el.async 	= true;
		el.id 		= 'ymap-jssdk';
		document.getElementsByTagName('head')[0].appendChild(el);

		this.state =
		{
			current : 100, 
			logged	: tocken ? 2 : 0
		}
	}
	render()
	{
		let unlogged = false;
		let states = []
			.concat(layouts.extended_routes)
				.concat(layouts.menu)
					.concat(layouts.main_menu)
						.concat(layouts.profile)
							.concat(layouts.help)
								.concat(layouts.bells)
									.concat(layouts.comments)
		for( let e in states )
		{	
			if(  
				states[e].unlogged 
				&& "/"+states[e].route == this.props.location.pathname 
			)
			{
				unlogged = true;
				break;
			}				
		}
		//console.log(this.state.logged, unlogged);
		let content;
		switch(true)
		{
			case this.state.logged == 2 && !unlogged :
				//content = <LayoutLogin onLogin={this.onLogin} />
				//break;
			case this.state.logged == 2 || unlogged :
				content = <LayoutContent
					current={this.state.current}
					onCurrent={this.onCurrent}
					onChangeStyle={ style => this.props.onChangeStyle( style ) } 
					onLogin={this.onLogin} 
				/>
				break;
			case this.state.logged == 1:
				content = <LayoutScope onAccept={this.onAccept}  onDecline={this.onDecline} text={layouts.user.scopes}/>
				break;
			case this.state.logged == 0:
			default:
				content = <LayoutLogin onLogin={this.onLogin} />
				break;
		}
		return <div className="layout block w-100">
			<LayoutHeader 
				name={this.props.name}
				current={this.state.current}
				onCurrent={this.onCurrent}	
				onLogout={this.onLogout}
				logged={this.state.logged}
			/>
			{content}
		</div>
	}
	onAccept = () =>
	{
		this.props.token({
			variables:
				{
					input: {
						grant_type: "ciba",
						assertion: process.env.REACT_APP_ASSERTION,
						auth_req_id: this.state.auth_req_id
					}
				},
			update: (store, { data: { token } }) =>
			{
				AppToaster.show({
					intent: Intent.SUCCESS,
					icon: "tick",
					message: __("You enter by User")
				});
				this.setState({ logged:2 });
				localStorage.setItem(
					'token',
					token.access_token
				);				
				//TODO: get userInfo and put to store
				this.props.history.replace("/");
				window.location.reload();
			},
			refetchQueries: [ { query: l_userInfo, variables: {}}]
		})


	}
	onDecline =() =>
	{
		this.setState({ logged:0 });
	}
	
	onLogin = (login, password) =>
	{

		this.props.authorize({
			variables:
				{"input": {
						"assertion": process.env.REACT_APP_ASSERTION,
						"user_code": password || "",
						"login_hint": login || "",
						"scope": [""]
					}
				},
			update: (store, {data: {authorize}} ) =>
			{
				if(authorize.auth_req_id)
				{
					this.setState({ logged:1, auth_req_id: authorize.auth_req_id });
				}
			}
		});
	}
	onLogout = () =>
	{
		this.setState({ logged:0 });
		localStorage.removeItem( 'token' );
		window.location.reload();
	}
	onCurrent = i => 
	{
		//console.log(i);
		this.setState({current:i});
	}
}
// export default Layout;

export default compose(

	graphql(l_authorize, {"name": "authorize"}),
	graphql(l_token, {"name": "token"}),
	withApollo,
	withRouter
)(Layout);