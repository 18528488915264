import React, {Fragment} from 'react';


import { ApolloProvider } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory';

import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";

import { ApolloLink } from 'apollo-link';
import { HttpLink } from "apollo-link-http";
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';

import Body from "./Body";

import {__} from "./utilities/i18n";
import {AppToaster} from "./utilities/blueUtils";
import {Intent} from "@blueprintjs/core";

// const httpLink = createHttpLink({
//     uri: "https://lp-server.kb.protopia-home.ru/grapgql", credentials: 'same-origin'
// });
console.disableYellowBox = true;
const httpLink = new createHttpLink({
    uri: process.env.REACT_APP_SURI,
});

const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

  // console.log(process.env.REACT_APP_SURI);

    let new_headers;
    if(localStorage.getItem('token')){
        const token = localStorage.getItem('token')? localStorage.getItem('token') : "";
        new_headers = {
            ...headers,
            "authorization": 'Bearer ' + token
        }
    } else if(localStorage.getItem('client_token')){
        const token = localStorage.getItem('client_token')? localStorage.getItem('client_token') : "";
        new_headers = {
            ...headers,
            "authorization": 'Bearer ' + token
        }
    }else{
        new_headers = {...headers}
    }

    // return the headers to the context so httpLink can read them
    // const xxx = base64_encode( login +':'+ password );
     // console.log(new_headers);
    return {
        headers: new_headers
    }
});

const errorlink = onError(({ graphQLErrors, networkError, operation, forward }) => {

    if (graphQLErrors) {
        for (let err of graphQLErrors) {

            switch (err.extensions.code) {
                case "INTERNAL_SERVER_ERROR":
                    console.log(err);
                    break;
                case "FORBIDDEN":
                    console.log(err);
                    break;					
                default:
                    AppToaster.show({
                        intent: Intent.DANGER,
                        icon: "error",
                        message: __( err.message )
                    });
                    break;
            }
        }
    }



});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        // errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'network-only',
        // errorPolicy: 'all',
    },
}

const client = new ApolloClient({
    link: ApolloLink.from([
        errorlink,
        authLink,
        httpLink,

    ]),
    cache: cache,
    // , defaultHttpLink: false,

    defaultOptions: defaultOptions,
    // fetchOptions: {
    //     credentials: 'include'
    // }

})

//console.log(client);

class App extends React.Component
{

    render() {
        return (

            <ApolloProvider client={client}>
                    <Body/>
            </ApolloProvider>
        );
    }
}

export default App;