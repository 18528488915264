import React, {Component} from "react";
import {__} from "../utilities/i18n";
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import {  
	Card
 } from "@blueprintjs/core";



class LayoutLogin extends Component
{
	state={login: "", password: ""}

	login = (e) =>{this.setState({login: e.currentTarget.value})}
	passWord = (e) =>{this.setState({password: e.currentTarget.value})}

	// assertion
	//
	render()
	{

		//console.log(this.props);
		return <div className="layout-center">
			<Card 
				interactive={false}
				className="layout-message p-4 layout-center"
			>
				<div className="title">{__("Enter")}</div>
				<input 
					type="text" 
					className="w-100 input" 
					placeholder={__("enter login")}
					name="login"
					onChange={this.login}
					value={this.state.login}
				/>
				<input 
					type="password" 
					className="w-100 input" 
					placeholder={__("enter password")}
					name="password"
					onChange={this.passWord}
					value={this.state.password}
				/>
				<div className="btn btn-success w-100" onClick={this.onLogin} >
					{__("log in")}
				</div>
				<NavLink 
					className="btn btn-link w-100"
					to={"/register"}
				>
					{__("register")}
				</NavLink>
			</Card>
		</div>
	}
	onLogin = evt =>
	{
		evt.preventDefault();
		this.props.onLogin(this.state.login, this.state.password);
	}
}
// export default LayoutLogin;

export default withRouter(LayoutLogin);

// export default graphql(l, {"name": "authorize"})(LayoutLogin);

//	,
// 	withApollo,