import React, {Component, Fragment} from "react";
import Loading from "../../../utilities/Loading";
import layouts from "../../../layouts";
import CategoryForm from "./CategoryForm";
import {getQuery, getMutation, addEmpty} from "../form";
import { Tab, Tabs, ButtonGroup, Button, Intent } from "@blueprintjs/core";
import {__} from "../../../utilities/i18n";
import {sprintf} from "../../../utilities/sprintf";
import {compose} from "recompose";
import {graphql, withApollo} from "react-apollo";
import {withRouter} from "react-router";

import gql from "graphql-tag";

class RecieverForm extends Component
{
	state = {
		currentTab : "rx",
		...this.props,
		
		themes : this.props.data.themes ? [...this.props.data.themes] : [],
		startthemes:this.props.data.themes ? [...this.props.data.themes] : [],
		addedthemesID:[],
		addedthemesList:null,
		lastthemesID:1,
		
		activate_sources : this.props.data.activate_sources ? [...this.props.data.activate_sources] : [],
		startactivate_sources:this.props.data.activate_sources ? [...this.props.data.activate_sources] : [],
		addedactivate_sourcesID:[],
		addedactivate_sourcesList:null,
		lastactivate_sourcesID:1,
		
		addedList:{ themes:[], activate_sources:[] }
	};
	render()
	{
		
		const __themes = this.getExtTabs();	
		let rdata = {};
		const state = {...this.state};
		for(var i in layouts.schema[this.props.data_type].apollo_fields)
		{
			rdata[i] = state[i];
		}		
		return <div className="category_card">
			<Tabs 
				id="TabsExample" 
				onChange={this.handleTabChange} 
				animate={true} 
				vertical={true} 
				className="text-right"
				selectedTabId={this.state.currentTab}
			>
				<Tab 
					id="rx" 
					key={0}
					title={__(this.props.data_type)} 
					panel={<CategoryForm 
						{ ...this.props }						
						data={ rdata }	
						isHiddenSave={ true }
						isHiddenClose={ true }
						isMain={ true }
						vertical={ true }					
						on={ this.onChange }
						addList ={ this.state.addedList }
					/>} 
				/>
				{__themes}	
			</Tabs>
			<div className="row">
				<div className="col-md-3">
				
				</div>
				<div className="col-md-7 ">
					<ButtonGroup className="p-2 tex-right">
						<Button
							text={__("Save")}
							onClick={this.onSave}
						/>
						
						<Button
							text={__("Close")}
							onClick={this.props.onClose}
						/>
					</ButtonGroup>
				</div>
			</div>			
		</div>
	}
	handleTabChange = currentTab =>
	{
		this.setState({ currentTab });
	}
	/*
	
	*/
	addNewTheme = evt =>
	{
		const data_type 		= evt.currentTarget.getAttribute("data_type");
		const scheme 			= this.getElementScheme(data_type);
		let themes  			= this.state[data_type].splice(0); 	// now selected Objects of data_type
		//const addList 			= "added" + data_type + "List";		// new created Objects list
		const listID			= "added" + data_type + "ID";		// 
		const lastIDfield 		= "last" + data_type + "ID";		// last free ID for add new Object 
		let addedthemesList  	= this.state.addedList[data_type] ? this.state.addedList[data_type].splice(0) : [];
		const id = this.state[ lastIDfield ] ? this.state[ lastIDfield ] : 1;
		// add empty element
		let elem  = addEmpty(scheme, {title:"New Element", _id: data_type + "_" + id});
		elem.isNew = true;		
		elem.color = "#777";
		elem.__typename = scheme;
		themes.push( elem );
		addedthemesList.push(elem);
		let addedthemesID = this.state[listID].slice(0);
		addedthemesID.push(elem);
		//console.log( this.state.addedthemesID.slice(0), addedthemesID );
		let state = { addedList:{} };
		state[ listID ] 			= addedthemesID;
		state.addedList[ data_type ] 	= addedthemesList;
		state[ data_type ] 			= themes;
		state[ lastIDfield ]		= id + 1;
		this.setState( state );
		console.log( data_type, state[ data_type ] );
		this.props.onChange( data_type, themes, id);
	}
	onThemeChange = (value, field, id) =>
	{
		//console.log( this.state );
		//console.group('RecieverForm.onThemeChange');
		let ii = -1;
		let themes = this.state.themes.slice(0);
		let theme = themes
			.filter((e, i) => {
				if(e._id == id) ii = i ;
				return e._id == id
			})[0];
		themes[ii][field] = value;
		//console.log( ii );
		//console.log( field, id, value );
		//console.log( themes );
		//console.log( this.state.themes );
		//console.groupEnd();
		this.setState({themes});
		this.props.onChange( "themes", value, id);
		
		
	}
	onChange = (value, field, id) =>
	{
		const {data_type} = this.props;
		let obj ={ addedList : {} };
		const component 		= this.getElementScheme(field);
		
		// если дочерний редактируемый элемент
		if( layouts.schema[ data_type].apollo_fields[field].recieved )
		{
			const startthemes		= "start" + field;
			const addedthemesID		= "added" + field + "ID";			
			const apollo_query		= layouts.schema[component].apollo_query;
			let fields 				= Object.keys(layouts.schema[component].apollo_fields).join(" ");
			const query				= getQuery(component);
			const cashe_themes 		= this.props.client.cache.readQuery({ query });
			const listID			= "added" + data_type + "ID";
			const themes 			= cashe_themes[apollo_query].concat( this.state[addedthemesID].slice(0) );
			console.log( apollo_query, themes );
			let new_themes, full_themes;
			// add to new themes label isNew
			full_themes = themes.map(e =>
			{
				const isNew = this.state[ startthemes ].filter( el => el._id != e._id ).length > 0;
				//console.log(isNew, e.title);
				//if(isNew)
					e.isNew = isNew;
				return e;
			});
			new_themes		= full_themes.filter(  e => value.filter(ee => ee._id == e._id).length > 0 )
			obj[ field ]	= new_themes;
			console.log( apollo_query, new_themes );
			// new Dropdawn full list.
			obj.addedList[ field ] 	= themes;
			this.props.onChange( field, new_themes, id);
		}
		else 
		{
			obj[ field ] = value;
			this.props.onChange( field, value, id);
		}
		console.log(value, field, id, obj);
		this.setState( obj );
	}	
	getElementScheme(field)
	{
		const my_apollo_fields 	= layouts.schema[this.props.data_type].apollo_fields;
		const my_field 			= my_apollo_fields[field];
		return my_field.component;
	}
	getExtTabs()
	{
		const apollo_fields = Object.keys(layouts.schema[this.props.data_type].apollo_fields);
		const recieved = apollo_fields.filter(field => layouts.schema[this.props.data_type].apollo_fields[field].recieved);
		//console.log(recieved);
		let arr = [];
		recieved.forEach((res, n) =>
		{			
			arr.push( 
				<div className="tab-descr devider-top is-new" key={n * 1000} >
					{__(layouts.schema[this.props.data_type].apollo_fields[ res ].title)}
				</div> 
			);
			this.state[res].forEach((e, i) =>
			{
				//console.log(n * 1000 + 100 + i)
				const tb = <Tab
					key={ n * 1000 + 100 + i }
					id={ res + e._id } 
					className={e.isNew ? "is-new" : "not-new"}
					title={ e.title }
					panel={<Fragment>
						<div className="small is-new hidden">{e._id}</div>
						<div className="text-danger font-weight-light text-center">
						{
							e.isNew 
								? 
								sprintf(
									__( "this %s no added to this %s yet." ), 
									__( e.__typename ), 
									__( this.props.data_type )
								)
								: 
								"..."
						}
						</div>
						<CategoryForm 					
							{...e}						
							data={e}	
							ID={e._id}
							isHiddenSave={true}
							intent={Intent.PRIMARY}
							isHiddenClose={true}
							data_type={e.__typename}
							on={ this.onThemeChange }					
							vertical={ true }	
						/>
					</Fragment>} 
				/>
				//console.log( tb );
				arr.push( tb );
			});		
			arr.push( <div className="tab-descr" key={n * 1000 + 1} >
				<Button 
					icon="plus" 
					minimal={true} 
					fill={true} 
					intent={Intent.DANGER} 
					className="bp3-btn-sm flex-sm-right flex-xm-center pr-3"
					data_type={res}
					onClick={this.addNewTheme}
				/>
			</div>);				
		});
		//console.log(arr);
		return arr;
	}		
}

export default compose(
	withApollo,
	withRouter
)(RecieverForm);
