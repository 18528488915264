import React, {Component, Fragment} from "react";
import BasicState from "./BasicState";

class PlaceState extends BasicState
{
	myState=()=>
	{
		return "PlaceState";
	}
	getRoute = () =>
	{
		return "place";
	}
}
export default PlaceState;